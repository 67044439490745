import React from 'react';
import { RouteComponentProps } from 'react-router';

import MarkDown from '../component/markdown';
import MDExample from '../component/markdownExample';
import { Paper } from '@material-ui/core';

const MarkdownEditor: React.FC<RouteComponentProps> = ()=>{
    return <Paper style={{padding: 15}}>
        <MarkDown source={MDExample}/>
    </Paper>;
}

export default MarkdownEditor;