import React from 'react';

const BBCodeParser:any = require('bbcode-to-react');

export interface BBCodeProps {
    content:string;
    [x:string]:any;
}

const BBCode: React.FC<BBCodeProps> = (props)=>{
    const {content, ...rest} = props;
    const parsed = BBCodeParser.toReact(props.content);
    return <div {...rest}>
        {parsed}
    </div>;
}

export default BBCode;