import React from 'react';
import { Typography, Button, makeStyles, createStyles, Theme, Box } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { RouteComponentProps } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(1,1),
    },
    greyBox: {
        padding: theme.spacing(5,5),
        backgroundColor: theme.palette.grey[200]
    },
    redBox: {
        padding: theme.spacing(5,5),
        backgroundColor: red[100]
    }
  }),
);


export const Error404: React.FC<RouteComponentProps> = (props)=>{
    const classes = useStyles(props);
    return <Box className={classes.greyBox}>
        <Typography variant="h2">404</Typography>
        <Typography variant="h5" gutterBottom>Página não encontrada.</Typography>
        <Button color="primary" className={classes.button} onClick={()=>props.history.goBack()}> Voltar </Button>
    </Box>;
}

export const Error401: React.FC<RouteComponentProps> = (props)=>{
    const classes = useStyles(props);
    return <Box className={classes.redBox}>
        <Typography variant="h2">401</Typography>
        <Typography variant="h5" gutterBottom>Acesso Negado.</Typography>
        <Button color="secondary" className={classes.button} onClick={()=>props.history.goBack()}> Voltar </Button>
    </Box>;
}

export const Error500: React.FC<RouteComponentProps> = (props)=>{
  const classes = useStyles(props);
  return <Box className={classes.redBox}>
      <Typography variant="h2">500</Typography>
      <Typography variant="h5" gutterBottom>Erro no sistema</Typography>
      <Button color="primary" className={classes.button} onClick={()=>props.history.goBack()}> Voltar </Button>
  </Box>;
}