import React from 'react';

import { Container, CssBaseline, Avatar, makeStyles, createStyles, Theme, Typography, TextField, Button, Grid} from '@material-ui/core';
// import { Link as RouteLink } from 'react-router-dom';

//import * as Icon from '@material-ui/icons';

import Logo from '../../../media/logo512.png';
import { blue } from '@material-ui/core/colors';

const avatarSize = 100;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
        body: {
          backgroundColor: theme.palette.common.white,
        },
      },
      paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        width: avatarSize,
        height: avatarSize,
        borderColor: blue[500],
        borderWidth: 2,
        borderStyle: 'double',
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
  })
);

interface LoginProps {
    submit: (fields:any)=>void;
}

const Login: React.FC<LoginProps> = (props)=>{
    const classes = useStyles(props);

    const [fields,setFields] = React.useState({
        values:{
            user: "",
            pass: "",
        },
        errors:{
            user: false,
            pass: false,
        }
    });

    const onChange = (e)=>{
        setFields({ ...fields,
            values: {...fields.values, [e.currentTarget.name]: e.currentTarget.value },
            errors: {...fields.errors, [e.currentTarget.name]: e.currentTarget.value.length<=0 },
        });
    };

    const onSubmit = (evt:React.FormEvent<HTMLFormElement>)=>{
        evt.preventDefault();
        let errors = {...fields.errors};
        Object.keys(fields.values).forEach(k => errors[k] = fields.values[k].length<=0);
        setFields({...fields, errors});
        
        if(Object.keys(errors).some(k => errors[k])) return;
        props.submit(fields.values);
    }

    return <Container component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
      <Avatar className={classes.avatar} src={Logo}/>
      <Typography component="h1" variant="h5"> {'Extrator Node'} </Typography>
      <form className={classes.form} noValidate onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Usuário"
          name="user"
          autoComplete="node_usuario"
          autoFocus
          value={fields.values.user}
          error={fields.errors.user}
          onChange={onChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="pass"
          label="Senha"
          type="password"
          autoComplete="node_senha"
          value={fields.values.pass}
          error={fields.errors.pass}
          onChange={onChange}
        />
        {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Lembrar Login" /> */}
        <Button fullWidth type="submit" variant="contained" color="primary" className={classes.submit}> {'Entrar'} </Button>
        <Grid container>
          <Grid item xs>
            {/* <Link component={RouteLink} to="/" variant="body2">
              {'Esqueci minha senha'}
            </Link> */}
          </Grid>
          {/* <Grid item>
            <Link component={RouteLink} to="/" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid> */}
        </Grid>
      </form>
    </div>
  </Container>;
}

export default Login;