import React from 'react';
import { RouteComponentProps } from 'react-router';

import { Paper, makeStyles, createStyles, Typography, Grid, Button, Divider } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import DaileonForm, { FormFields, DaileonFormButton } from '../../main/component/daileonForm';
import { CompatibleTextField, CompatibleSwitch } from '../../main/component/daileonCommonInput';
import SelectField from '../../main/component/selectField';
import Util from './../../main/service/util';


const useStyles = makeStyles( theme => createStyles({
        paper: {
            padding: theme.spacing(1),
        },
        divider: {
            margin: theme.spacing(1,0,1,0),
        },
        addButton: {
            color: theme.palette.common.white,
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[600],
            },
        },
    }),
);

const CadastroAcesso: React.FC<RouteComponentProps> = (props)=>{
    const classes = useStyles({});

    const initialValues = props.location.state;
    const editCode = initialValues && initialValues.id;

    const save = async (data:any)=>{
        const postData = {
            action: editCode?'update':'insert',
            id: editCode,
            ...data,
            dependencia: data.dependencia.value,
        };
        const [,failed] = await Util.backendRequestHandled('auth/acesso','POST',postData,true,true,true);
        if(!failed){
            props.history.push('/acesso');
        }
    }

    //------- FORM ------------
    const formFields:FormFields = [
        {label: "Nome", name: "label", defaultValue: "", grid:{ sm:6 }, 
            type: CompatibleTextField, props:{},
        },
        {label: "Icone", name: "icon", defaultValue: "", grid:{ sm:6 },
            type: CompatibleTextField, props:{ },
        },
        {label: "Caminho", name: "path", defaultValue: "", grid:{ sm:6 },
            type: CompatibleTextField, props:{ },
        },
        {label: "Dependencia", name: "dependencia", defaultValue: {label: "Nenhuma", value: ""}, grid:{ sm:6 },
            type: SelectField, props:{
                dataURI:"auth/acesso", dataPath: "acesso", optionLabelPath: "label", optionValuePath: "id",
                isSearchable: true,
                appendOptions: [
                    {label: "Nenhuma", value: ""},
                ],
            },
        },
        {label: "Mostrar no menu", name: "displayMenu", defaultValue: true,
            type: CompatibleSwitch, props: { }
        },
        {label: "Acesso Rápido", name: "displayAcessoRapido", defaultValue: true,
            type: CompatibleSwitch, props: { }
        },
    ]
    const formButtons:DaileonFormButton[] = [
        {label: "Reverter",
            props: {variant: "contained", color: "default"},
            action: async (form)=>{
                await form.clear();
            },
        },
        {label: "Salvar", isSubmit: true,
            props: {variant: "contained", color: "primary"},
            action: (form)=>{
                const allValid = form.validateAll();
                if(allValid){
                    const values = form.getValues();
                    save(values);
                } else {
                    form.highlightInvalid();
                }
            },
        },
    ]

    return <Paper className={classes.paper}>
            <Grid container direction='row' justify='space-between' alignItems='center'>
                <Typography variant='h5'>{editCode ? `Editando acesso (cod. ${editCode})` : "Nova rota de acesso"}</Typography>
                <Button variant='contained' color='default' onClick={()=>props.history.goBack()}>Voltar</Button>
            </Grid>
            <Divider className={classes.divider}/>

            <DaileonForm
                fields={formFields}
                buttons={formButtons}
                initialValues={initialValues}
                />
        </Paper>;
}

export default CadastroAcesso;