import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthService from '../service/authService';
import Util from '../../../main/service/util';

interface IProtectedRouteProps {
    path:string;
    component: React.Component;
    render?: (obj: any)=>any;
    [x:string]:any;
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({path, component: TargetComponent, render, ...params}: any) => {
    return <Route
        {...params}
        render={props => {
            if(!AuthService.authenticated()) {
                const desired_path = window.location.pathname;
                Util.setDesiredPath(desired_path);
                return <Redirect to="/login"/>;
            }
            if(!AuthService.checkPermission(path)) return <Redirect to="/401"/>;
            return TargetComponent ? <TargetComponent {...props} {...params}/> : render(props);
        }}
    />;
}
 
export default ProtectedRoute;