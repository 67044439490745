import React from 'react';
import { FixedSizeList as List } from "react-window";
import { MenuListComponentProps } from 'react-select/src/components/Menu';

class VirtualizedMenuList extends React.Component<MenuListComponentProps<any>,any> {
    render() {
        const { options, children, maxHeight, getValue, itemHeight=50 }:any = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * itemHeight;
        const itemCount = children.length || 0;
        const totalHeight = itemCount*itemHeight;
        const needsScroll = Boolean(totalHeight>maxHeight);

        return (
        <List
            width={''}
            height={needsScroll?maxHeight:totalHeight}
            itemCount={itemCount}
            itemSize={itemHeight}
            initialScrollOffset={needsScroll?initialOffset||0:0}
        >
            {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
        );
    }
}

export default VirtualizedMenuList;