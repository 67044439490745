import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import AuthService from '../service/authService';

export type ProtectedButtonProps = ButtonProps & {
    path:string;
};

const ProtectedButton:React.FC<ProtectedButtonProps> = ({path,children,...props}:ProtectedButtonProps)=>{
    if(!AuthService.checkPermission(path)) return null;
    return <Button {...props}>{children}</Button>;
}

export default ProtectedButton;