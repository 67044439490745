import React from 'react';
import { Modal, makeStyles, createStyles, Theme, Fade } from '@material-ui/core';

import ModalService from '../service/modalService';

export interface ModalSimplaItem {
    content: any;
    closeOnClick?: boolean;
    disableOverlay?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'absolute',
            width: 'auto',
            left: '50%',
            [theme.breakpoints.down('xs')]: {
                width: '95%',
            },
            top: '35%',
            transform: 'translateX(-50%)',
        },
    }),
);

const ModalSimpla: React.FC = () => {
    const classes = useStyles({});

    const queue = ModalService.getQueue();

    const opened = queue.length > 0;
    const next = queue[0] || null;
    return <>
        <Fade in={opened}>
            <Modal open={true}
                disableAutoFocus
                disableBackdropClick={next ? !next.closeOnClick : true}
                hideBackdrop={next?next.disableOverlay:false}
                onClose={() => next && ModalService.remove(next)} >
                <div className={classes.modal + " nooutline"}>
                    {next && next.content}
                </div>
            </Modal>
        </Fade>
    </>;
}

export default ModalSimpla;