import React from 'react';
import { Button, Grid, useMediaQuery, Theme } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { useTheme } from '@material-ui/styles';

export type FormFields = Array<DaileonFormField | DaileonFormLayoutProps>

export interface DaileonFormLayoutProps {
    append:JSX.Element;
    grid?:any;
}

export interface DaileonFormField {
    label?:string|null;
    name:string;
    type:any;
    defaultValue?:any;
    ignoreValue?:boolean;
    validation?:(value:any,form: DaileonForm)=>[boolean,string?];
    formatValue?:(value:any,form: DaileonForm)=>any;
    formatInitialValue?:(value:any,initialValues:any)=>any;
    props?:any;
    hidden?:boolean;
    grid?:any;
}

export interface DaileonFormButton {
    label:string;
    props?:ButtonProps;
    isSubmit?:boolean;
    action:(form:DaileonForm)=>void;
}

export interface DaileonFormProps {
    fields: FormFields;
    buttons?: Array<DaileonFormButton>;
    initialValues?:{[x:string]:any};
    onChange?:(form: DaileonForm, fieldName?: string, value?:any)=>void;
    [x:string]:any;
}

export interface FormSpaceProps {
    mode?: 'up' | 'down' | 'between' | 'only';
    point?: any;
}
export const Space:React.FC<FormSpaceProps> = ({mode='up',point=['md']})=>{
    const theme:Theme = useTheme();
    const mode_func = theme.breakpoints[mode];
    const show = useMediaQuery(mode_func.call(mode_func,point));
    return show && <span style={{margin: '0px 5px'}}/>;
};

class DaileonForm extends React.Component<DaileonFormProps,any> {
    constructor(props:any){
        super(props);
        this.state = {fields: this.getDefaults(true), highlightInvalid: false, lastFields: []};
    }
    _mounted=false;

    buttonStyle = {
        margin: "5px 10px 5px 0px",
    };

    componentDidMount(){this._mounted=true;}
    componentWillUnmount(){this._mounted=false;}

    static resolveInitValue(initialValues, fieldDef){
        if(initialValues && (initialValues[fieldDef.name]!==undefined && initialValues[fieldDef.name]!==null)){
            const val = initialValues[fieldDef.name];
            return fieldDef.formatInitialValue ? fieldDef.formatInitialValue(val,initialValues) : val;
        } else {
            return fieldDef.defaultValue;
        }
    }

    // Quando mudar os campos, seta valores padroes para os novos campos
    static getDerivedStateFromProps(props,state) {
        if (props.fields !== state.lastFields) {
            let newFields = {};
            props.fields.map(field=>{
                const fieldItem = field as DaileonFormField;
                if(!state.fields[fieldItem.name]){
                    if(fieldItem.type){
                        newFields[fieldItem.name]={
                            value: DaileonForm.resolveInitValue(props.initialValues,fieldItem),
                            valid: null,
                            ignore: fieldItem.ignoreValue || false
                        }
                    }
                } else {
                    newFields[fieldItem.name] = state.fields[fieldItem.name];
                }
                return null;
            });
            return {
                fields: newFields,
                highlightInvalid: state.highlightInvalid,
                lastFields: props.fields,
            };
        }
        return null;
    }

    submitAction:any = null;

    getDefault = (fieldName:string,initial=false)=>{
        const {fields,initialValues} = this.props;
        const fieldItem = fields.find((f:DaileonFormField)=>f.name===fieldName) as DaileonFormField;
        if(!fieldItem) return null;
        if(fieldItem.type){
            return {
                value: DaileonForm.resolveInitValue(initial && initialValues, fieldItem),// initial && initialValues && (initialValues[fieldItem.name]!==undefined && initialValues[fieldItem.name]!==null) ? initialValues[fieldItem.name] : fieldItem.defaultValue,
                valid: null,
                ignore: fieldItem.ignoreValue || false
            };
        }
        return null;
    }

    getDefaults = (initial=false)=>{
        let fieldValues:any = {};
        const {fields,initialValues} = this.props;
        fields.map((field)=>{
            const fieldItem = field as DaileonFormField;
            if(fieldItem.type){
                fieldValues[fieldItem.name]={
                    value: DaileonForm.resolveInitValue(initial && initialValues, fieldItem), //initial && initialValues && (initialValues[fieldItem.name]!==undefined && initialValues[fieldItem.name]!==null) ? initialValues[fieldItem.name] : fieldItem.defaultValue,
                    valid: null,
                    ignore: fieldItem.ignoreValue || false
                }
            }
            return null;
        });
        return fieldValues;
    }

    handleChange = (name:string, value:any)=>{
        let newState = {...this.state};
        const {validation, ignoreValue}:any = this.props.fields.find((field)=>{
            const fieldItem = field as DaileonFormField;
            if(fieldItem.type) return fieldItem.name===name;
            return false;
        });
        const valid = validation?validation(value,this):true;
        newState.fields[name] = {value: value, valid: valid, ignore: ignoreValue || false};
        if(this._mounted) this.setState(newState);
        if(this.props.onChange) this.props.onChange(this,name,value);
    }

    // Render
    renderField = ({label, name, type, validation, props, defaultValue, hidden}:DaileonFormField,idx:number,array:Array<any>) => {
        if(hidden===true) return null;
        const valid = validation && this.state.fields[name].valid;
        const error = this.state.highlightInvalid ? (valid && valid[0]===false?valid[1] || "":null) : null;
        let SubComponent = type;
        let control = <SubComponent label={label} name={name} value={this.state.fields[name].value} defaultValue={defaultValue} onChange={this.handleChange} error={error} {...props}/>;

        return <div key={idx}>{control}</div>;
    }
    renderButton = ({label, props, action, isSubmit}:DaileonFormButton,idx:number) => {
        let clickAction:any = ()=>console.warn(`Acao nao definida no botao: ${label}`);
        if(action) clickAction = action;
        if(isSubmit) this.submitAction = ()=>clickAction(this);
        const {style,...propsRest} = props;
        return <Button key={idx} style={{...this.buttonStyle, ...style}} onClick={()=>clickAction(this)} {...propsRest}>{label}</Button>
    }

    // Methods
    hasField = (fieldName:string)=>{
        return this.props.fields.find((f:any)=>f.name===fieldName)!==null;
    }
    getValues = ()=>{
        const {fields}:any = this.props;
        const {fields:fields_state} = this.state;
        let vals:any = {};
        fields.forEach(field_def => {
            if(!field_def.name) return;
            const field_state = fields_state[field_def.name];
            if(!field_state.ignore) vals[field_def.name] = field_def.formatValue?field_def.formatValue(field_state.value):field_state.value;
        });
        return vals;
    }
    getFieldValue = (fieldName:any, forceRaw:boolean=false)=>{
        const fieldDef:any = this.props.fields.find((f:any)=>f.name===fieldName);
        if(!fieldDef) return null;
        if(fieldDef.formatValue && !forceRaw) return fieldDef.formatValue(this.state.fields[fieldName].value);
        return this.state.fields[fieldName].value;
    }
    setValues = async (pairs:any)=>{
        const {fields} = this.state;
        let newFields = {};
        for(const key in fields){
            if(fields.hasOwnProperty(key)) newFields[key] = fields[key];
        }
        Object.keys(pairs).forEach(k => {
            const {ignoreValue}:any = this.props.fields.find((field)=>{
                const fieldItem = field as DaileonFormField;
                if(fieldItem.type) return fieldItem.name===k;
                return false;
            });
            const val = {value: pairs[k], valid: null, ignore: ignoreValue || false};
            if(val) newFields[k] = val;
        });
        if(this._mounted) await this.setState({fields: newFields});
    }

    clear = async (toInitial:boolean=true)=>{
        if(this._mounted) await this.setState({fields: this.getDefaults(toInitial)});
    }
    clearFieldValue = async (fieldName:string, toInitial=true)=>{
        const {fields} = this.state;
        let newFields = {};
        const val = this.getDefault(fieldName,toInitial);
        if(val){
            for(const key in fields){
                if(fields.hasOwnProperty(key)) newFields[key] = fields[key];
            }
            newFields[fieldName] = val;
            if(this._mounted) await this.setState({fields: newFields});
        }
    }
    clearFields = async (list:string[], toInitial=true)=>{
        const {fields} = this.state;
        let newFields = {};
        for(const key in fields){
            if(fields.hasOwnProperty(key)) newFields[key] = fields[key];
        }
        list.forEach(fieldName => {
            const val = this.getDefault(fieldName,toInitial);
            if(val) newFields[fieldName] = val;
        });
        if(this._mounted) await this.setState({fields: newFields});
    }
    isFieldValid = (fieldName:any)=>{
        return this.state.fields[fieldName].valid;
    }

    validateAll = (checkOnly=false)=>{
        let {fields} = this.state;
        let allValid = true;
        for(let field of this.props.fields){
            const fieldItem = field as DaileonFormField;
            if(!fieldItem.type) continue;
            if(checkOnly){
                if(allValid && (fieldItem.validation && fieldItem.validation(fields[fieldItem.name].value,this))[0]===false) allValid=false;
            } else {
                fields[fieldItem.name].valid = fieldItem.validation && fieldItem.validation(fields[fieldItem.name].value,this);
                if(allValid && fields[fieldItem.name].valid && fields[fieldItem.name].valid[0]===false) allValid=false;
            }
        }
        return allValid;
    }

    highlightInvalid = ()=>{
        if(this._mounted) this.setState({highlightInvalid: true});
    }

    render() {
        const {fields,buttons,initialValues,onChange,...others} = this.props;
        return <form onSubmit={(e)=>{e.preventDefault(); if(this.submitAction)this.submitAction();}} {...others}>
            <Grid container spacing={1} style={{flexGrow:1}}>
                {fields.map((field,idx,arr)=>{
                    let element:JSX.Element;
                    const fieldItem = field as DaileonFormField;
                    if(fieldItem.hidden) return null;
                    if(fieldItem.type) element = this.renderField(fieldItem,idx,arr);
                    else element = (field as DaileonFormLayoutProps).append;
                    const {xs=12,...rest} = field.grid || {};
                    const defaultGridProps = {xs};
                    return <Grid key={idx} item {...defaultGridProps} {...rest}>{element}</Grid>;
                })}
            </Grid>
            <div>
                <div style={{marginTop: 5}}>
                    {buttons && buttons.map((button,idx)=>this.renderButton(button,idx))}
                </div>
            </div>
        </form>;
    }
}

 
export default DaileonForm;