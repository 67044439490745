import React from 'react';

import { MuiPickersUtilsProvider, KeyboardDateTimePicker as MUIDateTimePicker, KeyboardDatePicker as MUIDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { FormHelperText } from '@material-ui/core';
import { parse } from 'date-fns';


export const DateTimePicker: React.FC<any> = (props)=>{
    const {name, label, format="HH:mm dd/MM/yyyy", onChange, error=null, value, defaultValue, ...rest} = props;

    const parsedValue = value===''?null:(typeof(value)==='string'?parse(value,format,0):value);

    function handleDateChange(date: Date | null) {
        onChange(name,date);
    }

    const hasError = error && {error: true};
    return <><MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <MUIDateTimePicker
            fullWidth
            margin='dense'
            inputVariant='outlined'
            className='m-0'
            label={label}
            format={format}
            value={parsedValue}
            {...hasError}
            ampm={false}
            onChange={handleDateChange}

            invalidDateMessage="Data inválida"
            invalidLabel="Desconhecido"
            maxDateMessage="Excede a data máxima"
            minDateMessage="Precede a data mínima"

            {...rest}
        />
    </MuiPickersUtilsProvider>
        {error && <FormHelperText error margin='dense'>{error}</FormHelperText>}
    </>;
};

export const DatePicker: React.FC<any> = (props)=>{
    const {name, label, format="dd/MM/yyyy", onChange, error=null, value, defaultValue, ...rest} = props;

    const parsedValue = value===''?null:(typeof(value)==='string'?parse(value,format,0):value);

    function handleDateChange(date: Date | null) {
        onChange(name,date);
    }

    const hasError = error && {error: true};
    return <><MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <MUIDatePicker
            fullWidth
            margin='dense'
            inputVariant='outlined'
            className='m-0'
            label={label}
            format={format}
            value={parsedValue}
            {...hasError}
            onChange={handleDateChange}

            invalidDateMessage="Data inválida"
            invalidLabel="Desconhecido"
            maxDateMessage="Excede a data máxima"
            minDateMessage="Precede a data mínima"

            {...rest}
        />
    </MuiPickersUtilsProvider>
        {error && <FormHelperText error margin='dense'>{error}</FormHelperText>}
    </>;
};