import Latinise from './latiniseMap';
import Axios from 'axios';
import AuthService from './../../modules/auth/service/authService';
import ModalService from './modalService';

const BASE82 = '#n4R,aVhk=lgDX&z9WEIfqxY6C07$T*ieO-%M_/jy!Pv:tB.2ZQSJAL8wpNUs+FHG1@;rKmdcb?35ou';
var bs82 = require('base-x')(BASE82);

const BACKEND_LIVE_PATH = "/backend/";
const BACKEND_DEV_PATH = "https://extrator.nodesistemas.com.br/backend/";
const BACKEND_EXTENSION = "";//".php";
const DEV_MODE = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
var desiredPathRedirect = "/";

export {DEV_MODE};

export default class Util {
    // Utilitarios de caminho
    static backendPath(uri:string) {
        if(DEV_MODE){
            return BACKEND_DEV_PATH+uri+BACKEND_EXTENSION;
        } else {
            return BACKEND_LIVE_PATH+uri+BACKEND_EXTENSION;
        }
    }

    static async backendRequest(uri:string, mode:string, data?:any){
        const token = AuthService.getToken();
        const headers = token?{'Authorization': 'Bearer '+token}:{};
        const config:any = {
            url: this.backendPath(uri),
            headers: headers,
            withCredentials: true,
            method: mode,
            data: data,
        };
        let response:any = {data:{success: false, error:[{desc: "O sistema inacessível no momento, desculpe o transtorno.", code: 0}]}};
        try {
            response = await Axios.request(config);
        } catch(error) { //error.request //error.response
            response = error.response || response;
        }
        return response;
    }

    static async backendRequestHandled(uri:string, mode:string, data?:any, showErrors:boolean=true, showLoading:boolean=false, showSuccess:boolean=false){
        const loadingModal = {...ModalService.common.loading};
        if(showLoading) ModalService.add(loadingModal);
        const response = await this.backendRequest(uri, mode, data);
        if(showLoading) ModalService.remove(loadingModal);

        const responseData = response.data || {};
        let hadErrors = false;
        if(showErrors && responseData.error && responseData.error.length>0) {
            hadErrors=true;
            ModalService.popoutError(responseData.error);
        }
        if(response.status!==200 && !responseData.error){
            hadErrors=true;
            if(DEV_MODE) {
                let fullErr = JSON.stringify(responseData);
                ModalService.popoutError([{desc: "[DEBUG] "+fullErr}]);
                console.log(fullErr);
            } else if(response.status===500){
                ModalService.popoutError([{desc: "O sistema está em manutenção, desculpe o transtorno."}]);
            } else {
                ModalService.popoutError([{code: response.status, desc: response.statusText}]);
            }
            // Se o request falha, pega a sessao denovo pra ter certeza que ainda é valida
            const [success,errors] = await AuthService.fetchSession();
            if(!success) ModalService.popoutError(errors);
        }
        if(!hadErrors){
            const successModal = ModalService.common.success;
            if(showSuccess){
                ModalService.add(successModal);
                await Util.waitFor(1100);
                ModalService.remove(successModal);
            }
        }
        return [responseData,hadErrors];
    }

    // Segura o caminho desejado depois do login
    static setDesiredPath(path:string){
        desiredPathRedirect = path;
    }
    static getDesiredPath(reset?:boolean){
        if(reset) desiredPathRedirect = "/";
        return desiredPathRedirect;
    }

    // Seleciona o primeiro caminho disponivel nos acessos
    static getHomePath(){
        const acesso:Array<any> = AuthService.getData().acesso;
        if(acesso){
            const firstPath = acesso.find(a=>a.displayMenu);
            if(firstPath) return firstPath.path;
        }
        return "/401";
    }


    // Custom Encoding
    static encode(data:string):string{
        return bs82.encode(Buffer.from(data, 'utf8'));
    }
    static decode(data:string):string{
        return bs82.decode(data).toString('utf8');
    }

    // Remove chars especiais
    static simplifyLatin(str:string):string{
        return str.replace(/[^A-Za-z0-9[\] ]/g,function(a){return Latinise.latin_map[a]||a});
    }

    // Converte objeto em parametros URL
    static urlParams(obj:any, prefix?:any){
        var str = [], p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ? this.urlParams(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
        return str.join("&");
    }

    // Compara strings ignorando case e chars especiais
    static lazyCompare(target:string,part:string):boolean{
        const keys = part.split(' '); // Splita a pesquisa em chaves
        if(keys.length===1 && keys[0]==="")return true; // Se for vazio da match
        let match = false;
        for(let k of keys){
            if(k==="") continue; // Se a chave estiver vazia, pula pra nao mostrar a lista inteira
            match = this.simplifyLatin(target).toLowerCase().trim().includes(this.simplifyLatin(k).toLowerCase().trim());
            if(match) return match; // Se der match na chave ja retorna true
        }
        return match;
    }


    // Promises
    static waitFor(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    static async waitUntil(func:()=>boolean){
        return new Promise((resolve,reject)=>{
            (function wait(){
                if(func()) return resolve();
                setTimeout(wait,30);
            })();
        })
    }
}