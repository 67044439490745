import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import DaileonTable, { TableColumn, setColumnSize } from '../../main/component/daileonTable';

import { Paper, makeStyles, createStyles, Typography, Divider, Grid, IconButton, Menu, MenuItem, Chip } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import Util from '../../main/service/util';
import ModalService from '../../main/service/modalService';
import ProtectedButton from '../auth/component/protectedButton';
import AuthService from '../auth/service/authService';

const useStyles = makeStyles( theme => createStyles({
        paper: {
            padding: theme.spacing(1),
        },
        divider: {
            margin: theme.spacing(1,0,1,0),
        },
        addButton: {
            color: theme.palette.common.white,
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[600],
            },
        },
        chips: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }),
);

const Usuario: React.FC<RouteComponentProps> = (props)=>{
    const PATH_CADASTRO = "/usuario-cadastro";

    const classes = useStyles({});

    const [dataList,setDataList] = useState<any[]>([]);
    const [loading,setLoading] = useState<boolean>(false);
    const [actionAnchors,setActionAnchors] = useState<any>({});

    React.useEffect(()=>{
        fetchData();
    },[]);

    const fetchData = async ()=>{
        setLoading(true);
        const [data,] = await Util.backendRequestHandled('base/usuario','GET');
        if(data) setDataList(data.usuario);
        setLoading(false);
    }

    //------- DATATABLE ------------

    const tableColumns:TableColumn[] = [
        { title: 'Codigo', field: 'id', ...setColumnSize(30,null,{textAlign:'center'}) },
        { title: 'Usuário', field: 'nome' },
        { title: 'Nome', field: 'nomeDisplay' },
        { title: 'Nível', field: 'nivel_nome'},
        { title: 'Rotinas Liberadas', render: rowData=>{
                const rotinas = JSON.parse(rowData.rotinas||null);
                return rotinas && <div className={classes.chips}>{rotinas.map((v,i)=>v.id && <Chip key={i} color='default' size='small' label={v.descricao + (v.preset!==null?" - "+v.preset:"")}/>)}</div>;
            }
        },
        { title: 'Ações', hidden: !AuthService.checkPermission(PATH_CADASTRO), ...setColumnSize(30,{textAlign:'right'},{textAlign:'right'}), render: rowData => {
            const anchor = actionAnchors[rowData.id] || null;
            const handleClick = evt=>setActionAnchors({...actionAnchors, [rowData.id]:evt.currentTarget});
            const clickEdit = ()=>{
                const rotinas = JSON.parse(rowData.rotinas||null);
                props.history.push(PATH_CADASTRO, {...rowData, rotinas: rotinas && rotinas.map(v=>(v.id && {label: v.descricao + (v.preset!==null?" - "+v.preset:""), value: v.id+(v.preset!==null?"_"+v.preset:""), preset: v.preset}))});
                handleClose();
            };
            const clickRemove = ()=>{
                const confirmModal = ModalService.common.confirm(
                    'Remover usuário "'+rowData.nome+'" ?',
                    'Tem certeza que deseja excluir o usuário "'+rowData.nome+'"?',
                    async ok=>{
                        if(ok){
                            await Util.backendRequestHandled('base/usuario?id='+rowData.id,'DELETE',null,true,true,true);
                            fetchData();
                        }
                    },'EXCLUIR','Cancelar','secondary'
                );
                ModalService.add(confirmModal);
                handleClose();
            };
            const handleClose = ()=>setActionAnchors({...actionAnchors, [rowData.id]:null});
            return <>
                <IconButton aria-label="actions" onClick={handleClick}>
                    <Icons.MoreVert/>
                </IconButton>
                <Menu
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={clickEdit}>Editar</MenuItem>
                    <MenuItem onClick={clickRemove}>Remover</MenuItem>
                </Menu>
            </>;
        }},
    ];

    return <Paper className={classes.paper}>
            <Grid container direction='row' justify='space-between' alignItems='center'>
                <Typography variant='h5'>Usuários</Typography>
                <ProtectedButton path={PATH_CADASTRO} variant='contained' color='inherit' className={classes.addButton} onClick={()=>props.history.push(PATH_CADASTRO)}>Novo Usuário</ProtectedButton>
            </Grid>
            <Divider className={classes.divider}/>
            <DaileonTable
                title="Definições de Usuário"
                columns={tableColumns}
                options={{}}
                isLoading={loading}
                data={dataList||[]}
                />
        </Paper>;
}

export default Usuario;