import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link as RouteLink } from 'react-router-dom';

import { Typography, Link, Card, makeStyles, createStyles, CardContent, Grid } from '@material-ui/core';

//import * as Icons from '@material-ui/icons';

const useStyles = makeStyles(
    createStyles({
        main: {
            width: '100%',
        },
        card: {
            minWidth: 200,
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    }),
);

const Principal: React.FC<RouteComponentProps> = ()=>{
    const classes = useStyles({});
    return <div>
        <Typography variant="h4" gutterBottom>Tela principal extrator (Esta tela é só pro admin no momento)</Typography>
        <p>Mostrar andamento de solicitacoes e etc</p>
        <Grid container spacing={3} className={classes.main}>
            <Grid item xs={3}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>Favoritos</Typography>
                        <Link component={RouteLink} to="/plano">Plano de desenvolvimento</Link><br/>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </div>;
}

export default Principal;