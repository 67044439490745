import React from 'react';
import SelectField from './selectField';



const SelectCheckBoxField: React.FC<any> = (props)=>{
    const {...rest} = props;
    return <SelectField 
            strictInput={true}
            showIndicator={true}
            hideSelectedOptions={false}
            isMulti={true}
            closeMenuOnSelect={false}
            backspaceRemovesValue={false}
            isClearable={false}
            tabSelectsValue={false}
            isSearchable={true}
            fetchOnType={false}
            allSelectedText={'Todos'}
            multiMode={'checkbox'}
            where={'in'}
            {...rest}/>;
}

export default SelectCheckBoxField;