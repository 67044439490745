import React from 'react';
import { TextField, Switch, FormControlLabel, FormHelperText } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';

export const CompatibleTextField: React.FC<TextFieldProps & any> = (props) =>{
    const {name,value,defaultValue,error,fullWidth=true,onChange,
        variant='outlined', margin='dense', className='m-0',...rest} = props;
    
    return <>
                <TextField  value={value}
                            className={className}
                            error={Boolean(error)}
                            onChange={(e)=>onChange(name,e.target.value)}
                            fullWidth={fullWidth}
                            variant={variant}
                            margin={margin}
                            {...rest}/>
                { error && <FormHelperText error margin='dense'>{error}</FormHelperText> }
            </>;
};

export const CompatibleSwitch: React.FC<TextFieldProps & any> = (props) =>{
    const {label,name,value,defaultValue,error,color="primary",onChange,...rest} = props;
    return <><FormControlLabel
        label={label}
        control={<Switch checked={value} onChange={(e)=>onChange(name,e.target.checked)} color={color} {...rest}/>}
        labelPlacement='end'
    />{ error && <FormHelperText error margin='dense'>{error}</FormHelperText> }
    </>;
};