import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import DaileonTable, { TableColumn, setColumnSize } from '../../../main/component/daileonTable';

import { Paper, makeStyles, createStyles, Typography, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemAvatar, Avatar, ListItemText, Icon } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import Util from '../../../main/service/util';
import ProtectedButton from '../../auth/component/protectedButton';
import DaileonForm, { FormFields, DaileonFormButton, Space } from '../../../main/component/daileonForm';
import { DateTimePicker } from '../../../main/component/dateTimePicker';
import SelectTagsField from '../../../main/component/selectTagsField';
import { CompatibleTextField } from '../../../main/component/daileonCommonInput';

import { format, formatDistanceStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import AuthService from './../../auth/service/authService';
import ModalService from '../../../main/service/modalService';
import BBCode from '../../../main/component/bbcode';

const useStyles = makeStyles( theme => createStyles({
        paper: {
            padding: theme.spacing(1),
        },
        divider: {
            margin: theme.spacing(1,0,1,0),
        },
        addButton: {
            color: theme.palette.common.white,
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[600],
            },
        },
        cellIcon: {
            fontSize: '1.25rem',
            marginRight: 5,
        },
        modalPaper:{
            minWidth: '60vw',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        typeAvatar: {
            
        },
    }),
);

const Consulta: React.FC<RouteComponentProps> = (props)=>{
    const PATH_NOVO = "/consulta-novo";
    const initialValues = props.location.state;
    
    const classes = useStyles({});

    const initDateStart = null; //startOfWeek(new Date())
    const initDateEnd = null; //new Date()

    const [minDate,setMinDate] = useState<Date>(initDateStart);
    const [maxDate,setMaxDate] = useState<Date>(initDateEnd);

    const [dataList,setDataList] = useState<any[]>([]);
    const [rotinaList,setRotinaList] = useState<any[]>([]);
    const [statusList,setStatusList] = useState<any[]>([]);

    const [loading,setLoading] = useState<boolean>(false);
    const [actionAnchors,setActionAnchors] = useState<any>({});

    React.useEffect(()=>{
        fetchData({
            //inicio: Math.round(initDateStart.getTime()/1000),
            //fim: Math.round(initDateEnd.getTime()/1000),
        });
    },[]);

    const fetchData = async (filtros?:any)=>{
        setLoading(true);
        const [data] = await Util.backendRequestHandled('thunder/requisicao?'+Util.urlParams(filtros),'GET');
        if(data) {
            setDataList(data.requisicao);
            if(data.rotina) setRotinaList(data.rotina.map(v=>({label: v.descricao+(v.nome?" - "+v.nome:""), value: v.id, data: v})));
            if(data.status) setStatusList(data.status.map(v=>({label: v.status, value: v.status})));
        }
        setLoading(false);
    }

    //------- FORM ------------
    const dateFormat = 'HH:mm dd/MM/yy';
    const formFields:FormFields = [
        {label: "Código", name: "codigo", defaultValue: "", grid:{ sm:5, md:3 },
            type: CompatibleTextField, props:{
                type:'number',
            },
        },
        {label: "Rotina", name: "rotina", defaultValue: [], formatValue: v=>(v&&v.map(i=>i.value))||"",
            type: SelectTagsField, props:{
                options: rotinaList, 
            },
        },
        {label: "Status", name: "status", defaultValue: [], formatValue: v=>(v&&v.map(i=>i.value))||"",
            type: SelectTagsField, props:{
                options: statusList,
            },
        },
        {label: "Requisitado de", name: "inicio", defaultValue: initDateStart, grid:{ sm:5, md:3 },
            type: DateTimePicker, props: {maxDate: maxDate, format: dateFormat}, formatValue: v=>(v&& Math.round(v.getTime()/1000))||"",
        },
        {append: <Space/>, grid:{sm: 'auto', style:{padding:0}}},
        {label: "Até", name: "fim", defaultValue: initDateEnd, grid:{ sm:5, md:3 },
            type: DateTimePicker, props: {minDate: minDate, format: dateFormat}, formatValue: v=>(v&&Math.round(v.getTime()/1000))||"",
        },
    ]
    const formButtons:DaileonFormButton[] = [
        {label: "Limpar",
            props: {variant: "contained", color: "default"},
            action: async (form)=>{
                await form.clear();
            },
        },
        {label: "Consultar", isSubmit: true,
            props: {variant: "contained", color: "primary"},
            action: (form)=>{
                const allValid = form.validateAll();
                if(allValid){
                    const values = form.getValues();
                    fetchData(values);
                } else {
                    form.highlightInvalid();
                }
            },
        },
    ];
    const onFormUpdate = (form:DaileonForm)=>{
        const codigo = form.getFieldValue('codigo');
        // Se setar o código, nao filtra por data
        if(codigo) form.setValues({inicio: null, fim: null});

        const inicio = form.getFieldValue('inicio',true);
        const fim = form.getFieldValue('fim',true);
        setMinDate(inicio);
        setMaxDate(fim);
    }

    //------- DATATABLE ------------
    const tableColumns:TableColumn[] = [
        { title: 'Codigo', field: 'id', ...setColumnSize(30,null,{textAlign:'center'}) },
        { title: 'Rotina', field: 'rotina', render: rowData =>{
            const params = JSON.parse(rowData.parametros);
            const preset = params.preset ? " ("+params.preset.label+")" : "";
            const rotina = rotinaList.find(r => r.value===rowData.rotina);
            return rotina && rotina.label + preset;
        }},
        { title: 'Status', field: 'status' },
        { title: 'Data Requisição', field: 'timestamp', render: rowData=>{
            const date = new Date(rowData.timestamp);
            return <>
                <div style={{display:'flex'}}>
                    <Icons.Today className={classes.cellIcon}/>
                    <span>{format(date,'dd/MM/yy')}</span>
                </div>
                <div style={{display:'flex'}}>
                    <Icons.AccessTime className={classes.cellIcon}/>
                    <span>{format(date,'HH:mm:ss')}</span>
                </div>
            </>;
        }},
        { title: 'Hora Conclusão / Tempo', field: 'concluido', render: rowData=>{
            if(!rowData.concluido) return "---";
            const begin = new Date(rowData.timestamp);
            const date = new Date(rowData.concluido);
            return <>
                <div style={{display:'flex'}}>
                    <Icons.AccessTime className={classes.cellIcon}/>
                    <span>{format(date,'HH:mm:ss')}</span>
                </div>
                <div style={{display:'flex'}}>
                    <Icons.Timer className={classes.cellIcon}/>
                    <span>{formatDistanceStrict(date,begin, {locale: ptBR})}</span>
                </div>
            </>;
        }},
        { title: 'Ações', ...setColumnSize(100,{textAlign:'right'},{textAlign:'right'}), render: rowData => {
            const anchor = actionAnchors[rowData.id] || null;
            const handleClick = evt=>setActionAnchors({...actionAnchors, [rowData.id]:evt.currentTarget});
            const clickClone = ()=>{
                const params = JSON.parse(rowData.parametros);
                const rotina = rotinaList.find(r => r.value===params.rotina);
                const state = {
                    rotina: rotina?{label: rotina.label, value: params.rotina}:null,
                    preset: params.preset,
                    ...params.parametros,
                }
                props.history.push(PATH_NOVO, state);
                handleClose();
            };
            const handleClose = ()=>setActionAnchors({...actionAnchors, [rowData.id]:null});
            const clickDownload = ()=>{
                const params = JSON.parse(rowData.parametros);
                const sel_preset = params.preset? params.preset.label : "";
                const rotina = rotinaList.find(r => r.value===rowData.rotina).data;
                const rotina_params = rotina && JSON.parse(rotina.parametros);
                const preset = rotina_params && rotina_params.presets.find(p => p.label===sel_preset);
                const formats = (preset && preset.downloadFormats) || [];

                if(formats.length<2){
                    const f = (formats[0] && "&type="+formats[0].format) || "";
                    window.open(Util.backendPath("thunder/download?id="+rowData.id+"&token="+AuthService.getToken() + f ), '_blank');
                    return;
                }

                let modal = {closeOnClick: true, disableOverlay: true, content:null};
                const handleModalClose = ()=>ModalService.remove(modal);
                const handleListItemClick = fmt=>{
                    window.open(Util.backendPath("thunder/download?id="+rowData.id+"&type="+fmt+"&token="+AuthService.getToken()), '_blank');
                    handleModalClose();
                }
                modal.content = <Dialog onClose={handleModalClose} open={true}>
                    <DialogTitle>{"Selecione o tipo"}</DialogTitle>
                    <List>
                    { formats.map((f,k)=> 
                        <ListItem key={k} autoFocus button onClick={() => handleListItemClick(f.format)}>
                            <ListItemAvatar><Avatar className={classes.typeAvatar}> <Icon>{f.icon}</Icon> </Avatar></ListItemAvatar>
                            <ListItemText primary={f.label} />
                        </ListItem>
                    ) }
                    </List>
                </Dialog>;
                ModalService.add(modal);   
            }
            const clickLog = ()=>{
                // Fetch log
                Util.backendRequestHandled('thunder/getlog?id='+rowData.id,'GET',null,true,true,false).then(([response,hadErrors])=>{
                    if(hadErrors) return;
                    const bbcode = String(response);
                    let modal = {closeOnClick: true, disableOverlay: true, content:null};
                    const handleModalClose = ()=>ModalService.remove(modal);
                    modal.content = <Dialog onClose={handleModalClose} fullWidth={true} maxWidth='sm' open={true}>
                        <DialogTitle>
                            {"Log consulta nº "+rowData.id}
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleModalClose}>
                                <Icons.Close/>
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            <BBCode content={bbcode} style={{whiteSpace: 'pre-line'}}/>
                        </DialogContent>
                    </Dialog>;
                    ModalService.add(modal);
                });
                //
                handleClose();
            }
            const sucesso = rowData.status==='SUCESSO';
            const hasLog = AuthService.checkPermission('/consulta-log');
            return <>
                { (sucesso || !hasLog) &&
                <Tooltip title="Baixar Resultado">
                    <IconButton aria-label="actions" disabled={!sucesso} onClick={clickDownload}>
                        <Icons.GetApp/>
                    </IconButton>
                </Tooltip>
                }
                { !sucesso && hasLog &&
                <Tooltip title="Mostrar Log">
                    <IconButton aria-label="actions" disabled={sucesso} onClick={clickLog}>
                        <Icons.AssignmentLate/>
                    </IconButton>
                </Tooltip>
                }

                <Tooltip title="Outras Opções">
                    <IconButton aria-label="actions" onClick={handleClick}>
                        <Icons.MoreVert/>
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={clickClone}>Clonar Consulta</MenuItem>
                    { sucesso && hasLog && <MenuItem onClick={clickLog}>Mostrar Log</MenuItem> }
                </Menu>
            </>;
        }},
    ];

    return <Paper className={classes.paper}>
            <Grid container direction='row' justify='space-between' alignItems='center'>
                <Typography variant='h5'>Consultas</Typography>
                <ProtectedButton path={PATH_NOVO} variant='contained' color='inherit' className={classes.addButton} onClick={()=>props.history.push(PATH_NOVO)}>Nova Consulta</ProtectedButton>
            </Grid>
            <Divider className={classes.divider}/>
            <DaileonForm
                fields={formFields}
                buttons={formButtons}
                initialValues={initialValues}
                onChange={onFormUpdate}
                />
            <Divider className={classes.divider}/>
            <DaileonTable
                title="Consultas"
                columns={tableColumns}
                options={{}}
                isLoading={loading}
                data={dataList||[]}
                />
        </Paper>;
}

export default Consulta;