import React, { HTMLAttributes } from 'react';

import PropTypes from 'prop-types';
import { WhereSQL } from './fieldTypes';

import { CSSProperties } from '@material-ui/styles';
import { Typography, MenuItem, Chip, Paper, TextField, CircularProgress, IconButton, Tooltip, Checkbox, FormHelperText } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import { emphasize, Theme } from '@material-ui/core/styles';
import { withStyles, withTheme } from '@material-ui/styles';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { NoticeProps, MenuProps, MenuListComponentProps } from 'react-select/src/components/Menu';
import { ControlProps } from 'react-select/src/components/Control';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { IndicatorProps } from 'react-select/src/components/indicators';

import VirtualizedMenuList from './virtualizedMenuList';
import Util from '../service/util';

export interface OptionType { label:string; value:any; }

const styles:any = (theme:Theme) => ({
    input: {
      display: 'flex',
      padding: theme.spacing(0.5, 0, 0.1, 2),
      height: 'auto',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      bottom: 6,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 100,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
    indicator: {
      height: theme.spacing(5),
    },
    loadingIndicator:{
      margin: theme.spacing(0,1),
    },
});

function NoOptionsMessage(props: NoticeProps<OptionType>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;
type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;
function inputComponent({ children, inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props}>{children}</div>;
}
inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  children: PropTypes.node,
} as any;
function Control(props: ControlProps<OptionType>) {
  const { children, innerProps, innerRef, isFocused, hasValue, selectProps: { classes, variant='outlined', error, placeholder, isDisabled, TextFieldProps } } = props;
  return <><TextField
    fullWidth
    variant={variant}
    margin='dense'
    className='m-0'
    label={placeholder}
    disabled={isDisabled}
    InputProps={{
      inputComponent: inputComponent,
    }}
    error={Boolean(error)}
    InputLabelProps={{ shrink: Boolean(isFocused || hasValue) }}
    // eslint-disable-next-line
    inputProps={{
      className: classes.input,
      ref: innerRef,
      children,
      ...innerProps,
    }}
    {...TextFieldProps}
  />{
    error && <FormHelperText error margin='dense'>{error}</FormHelperText>
  }</>;
}
Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
} as any;
function Option(props: OptionProps<OptionType>) {
  const { isMulti, selectProps: {multiMode} } = props;
  const checkboxMode = isMulti && multiMode==='checkbox';
  if(checkboxMode){
      return <MenuItem ref={props.innerRef} selected={props.isFocused} component="div" style={{marginLeft: '-10px'}} {...props.innerProps}>
        <Checkbox checked={props.isSelected} />
        <span style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'flex-start',
          textOverflow: 'ellipsis',
        }}>{props.children}</span>
      </MenuItem>;
  }
  return <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}>
      <span style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'flex-start',
        textOverflow: 'ellipsis',
      }}>{props.children}</span>
    </MenuItem>;
}
Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
} as any;
function Placeholder(props: PlaceholderProps<OptionType>) {
  return null;
  // return (
  //     <Typography
  //     color="textSecondary"
  //     className={props.selectProps.classes.placeholder}
  //     {...props.innerProps}
  //     >
  //     {props.children}
  //     </Typography>
  // );
}
Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;
function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}
SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;
function ValueContainer(props: ValueContainerProps<OptionType>) {
  const { children, isMulti, options, hasValue, selectProps: {multiMode,allSelectedText} } = props;
  if(isMulti && multiMode==='checkbox'){
      const [_display,_input]:any = children;
      const valueText = hasValue && (_display.length>=options.length && _display.length>1 ? allSelectedText : ( _display.length < 4 ? _display.map(v=>v.props.data.label).join(', ') : `${_display.length} selecionados` ));
      return <div className={props.selectProps.classes.valueContainer}>
              {hasValue && valueText}
              {_input}
             </div>;
  } else {
      return <div className={props.selectProps.classes.valueContainer}>{children}</div>;
  }
}
ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
} as any;
function MultiValue(props: MultiValueProps<OptionType>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={props.selectProps.classes.chip}
      onDelete={props.removeProps.onClick}
      deleteIcon={<Icons.Cancel {...props.removeProps} />}
    />
  );
}
MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
} as any;
function Menu(props: MenuProps<OptionType>) {
  return (
    <Paper elevation={5} className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}
Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
} as any;
function MenuList(props: MenuListComponentProps<OptionType>){
  const { isMulti, selectProps: {multiMode, showSelectButtons, selectAllOptions} } = props;
  const checkboxMode = isMulti && multiMode==='checkbox';
  let optionButtons = null;
  if(checkboxMode && showSelectButtons){
      optionButtons = <Paper elevation={2} style={{marginBottom: 5}}>
        <Tooltip title="Marcar Todos">
          <IconButton color="default" onClick={()=>selectAllOptions(true)}>
            <Icons.CheckBox/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Desmarcar Todos">
          <IconButton color="default" onClick={()=>selectAllOptions(false)}>
            <Icons.CheckBoxOutlineBlank/>
          </IconButton>
        </Tooltip>
      </Paper>;
  }
  return <>
      {optionButtons}
      <VirtualizedMenuList {...props}>
      {props.children}
      </VirtualizedMenuList>
    </>;
}
function LoadingIndicator(props: IndicatorProps<OptionType>) {
    const { selectProps: { classes } } = props;
    return <CircularProgress
              className={classes.loadingIndicator}
              disableShrink
              size={20}
              thickness={3}
              />;
}
const components:any = {
  Control,
  Menu,
  MenuList,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  LoadingIndicator,
};

export interface SelectFieldProps {
    onChange:(name:string,value:any)=>void;

    options?: OptionType;
    dataURI?: string;
    dataPath?: string;
    optionLabelPath?: string;
    optionValuePath?: string;
    dataParser?: (response:any)=>any;
    appendOptions?: OptionType[];
    loadOnMount?:boolean;

    multiMode?:'tag'|'checkbox';

    strictInput?:boolean;
    disableCache?:boolean;

    error?: string;

    where?: WhereSQL;
}

interface CachedData {
    time:number;
    result:any;
}

const cacheDecay = 30000; // ms

class SelectField extends React.Component<any,any> {
    _mounted=false;
    _lastCall:NodeJS.Timeout|null = null;
    _ready = false;
    _searchStack:Array<number> = [];
    _currentOptions:Array<any> = [];

    _firstSet = false;

    _cache:{[x:string]:CachedData} = {};

    state = {
      fetching: false,
      currentOptions: [],
    };

    componentDidMount() {
      this._mounted=true;
      if(this.props.dataURI) this.getOptions();
    }
    componentWillUnmount() {this._mounted=false;}

    isCached = (key:string):[boolean,any]=>{
        const data = this._cache[key];
        if(!data) return [false,null];
        if(Date.now()-data.time > cacheDecay) {
            delete this._cache[key];
            return [false,null];
        }
        return [true,data.result];
    }
    addCache = (key:string, data:any)=>{
        this._cache[key] = {time: Date.now(), result: data};
    }

    fetch = (filter:string):Promise<any>=>{
      return new Promise(async (resolve)=>{
        this.setState({fetching: true});

        // [TODO] Passar input de texto pra filtrar (quando tiver)
        const [response,] = await Util.backendRequestHandled(this.props.dataURI,'GET',null,false);

        if(!this.props.disableCache) this.addCache(filter, response);
        resolve(response);
        this.setState({fetching: false});
      });
    }

    getOptions = async (searchTerm:string="")=>{
        let searchID = this._searchStack.length;
        this._searchStack.push(searchID);
        if(this._lastCall!=null){
            clearTimeout(this._lastCall);
            this._lastCall=null;
        }
        let [hasCache,cached] = this.isCached(searchTerm);
        let opts:Array<any> = [];
        const format = this.props.dataParser || ((result:any) => result[this.props.dataPath] && result[this.props.dataPath].map((item)=>({label: item[this.props.optionLabelPath], value: item[this.props.optionValuePath]})));
        if(!hasCache){
            this._lastCall = setTimeout(()=>{
                this._ready=false;
                this.fetch(searchTerm).then((result:any)=>{
                    if(this._searchStack[this._searchStack.length-1]!==searchID) return;
                    opts = format(result);
                    this._ready=true;
                });
            },this._firstSet?800:0);
        } else {
            opts = format(cached);
            this._ready=true;
        }
        let canceled = false;
        await Util.waitUntil(()=>{
            let isLatest = this._searchStack[this._searchStack.length-1]===searchID;
            if(!isLatest) canceled=true;
            return this._ready || !isLatest;
        });
        this._searchStack.splice(this._searchStack.indexOf(searchID),1);
        if(canceled) return;

        this._ready=false;
        if(!this._firstSet){
          this._firstSet=true;
        }

        if(this.props.appendOptions) opts = [...this.props.appendOptions,...opts]; // Adiciona as opcoes extra se existir
        this.setState({...this.state,currentOptions: opts});
    }

    onInputChange = (searchTerm:string, meta:any)=>{
        if(!this.props.dataURI || !this.props.fetchOnType) return;
        if(meta.action==='input-change') this.getOptions(searchTerm);
    }

    inputKeyHandler = (evt)=>{
        switch(evt.key){
            case "Home": evt.preventDefault();
              if(evt.shiftKey) evt.target.selectionStart = 0;
              else evt.target.setSelectionRange(0,0);
              break;
            case "End": evt.preventDefault();
              const len = evt.target.value.length;
              if(evt.shiftKey) evt.target.selectionEnd = len;
              else evt.target.setSelectionRange(len,len);
              break;
            case "Backspace":
              if(this.props.cleanOnBackspace) this.props.onChange(this.props.name, "");
              break;
        }
    }

    render() {
        const {classes, theme, label, options, dataURI, value, error, onChange, showIndicator=true, strictInput=true, multiMode='tag',
              allSelectedText='Todos', hideSelectedOptions=false, isSearchable=false, showSelectButtons=true, disabled=false,
              fetchOnType, cleanOnBackspace, dataPath, dataParser, optionLabelPath, optionValuePath, appendOptions, isInvalid, disableCache, loadOnMount, ...rest} = this.props;

        const disableIndicator = !showIndicator? {
          indicatorSeparator: (base:any)=>({...base, opacity: 0, minHeight: 20}),
          dropdownIndicator: (base:any)=>({...base, display: 'none'}),
        } : {};
          
        const selectStyles = {
          input: (base: CSSProperties) => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
              font: 'inherit',
            },
          }),
          ...disableIndicator,
        };

        const opts = dataURI?this.state.currentOptions:options;
        const SelectType:any = strictInput?Select:CreatableSelect;
        return <SelectType
                  value={value}
                  options={opts}
                  onInputChange={this.onInputChange}

                  multiMode={multiMode}
                  allSelectedText={allSelectedText}
                  selectAllOptions={(_selected:boolean)=>onChange(this.props.name,_selected?opts:[])}
                  showSelectButtons={showSelectButtons}

                  error={error}

                  hideSelectedOptions={hideSelectedOptions}
                  placeholder={label}
                  isLoading={this.state.fetching}
                  classes={classes}
                  components={components}
                  isSearchable={isSearchable}
                  onChange={(_value)=>onChange(this.props.name,_value)}
                  noOptionsMessage={()=>"Nada encontrado"}
                  loadingMessage={()=>"Carregando..."}
                  formatCreateLabel={(_input)=>`"${_input}"`}
                  styles={selectStyles}
                  onKeyDown={this.inputKeyHandler}
                  allowCreateWhileLoading={true}
                  blurInputOnSelect={false}
                  isDisabled={disabled}

                  {...rest}
                  />;
    }
}


export default withTheme(withStyles(styles)(SelectField));