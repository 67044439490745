import React from 'react';
import MaterialTable, { Column, Localization, MaterialTableProps } from 'material-table';
import { Paper } from '@material-ui/core';

export type TableColumn = Column<any>;

export function setColumnSize(size:any, headerStyle?:any, cellStyle?:any){
    //return {headerStyle:{maxWidth:size, padding:'14px 25px 14px 14px',...headerStyle}, cellStyle:{maxWidth:size, padding: 5, ...cellStyle}};
    //return {headerStyle:{maxWidth:size, ...headerStyle}, cellStyle:{maxWidth:size, ...cellStyle}};
    return {headerStyle:{minWidth:size, width:size, padding:'0px 0px 0px 14px',...headerStyle}, cellStyle:{minWidth:size, width:size, padding: 0, ...cellStyle}};
    //return {headerStyle, cellStyle};
}

const LocalizationMap:{[language:string]:Localization} = {
    ptBR: {
        body: {
            emptyDataSourceMessage: "Nenhuma informação a mostrar.",
            addTooltip: "Adicionar",
            deleteTooltip: "Apagar",
            editTooltip: "Editar",
            filterRow: {
                filterTooltip: "Filtrar",
            },
            editRow: {
                deleteText: "Tem certeza que deseja apagar esta linha?",
                cancelTooltip: "Cancelar",
                saveTooltip: "Salvar",
            }
        },
        grouping: {
            placeholder: "Arraste os cabeçalhos...",
        },
        header: {
            actions: "Ações",
        },
        pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "linhas",
            labelRowsPerPage: "Linhas por página:",
            firstTooltip: "Primeira Página",
            previousTooltip: "Página Anterior",
            nextTooltip: "Próxima Página",
            lastTooltip: "Última Página",
        },
        toolbar: {
            addRemoveColumns: "Adicionar ou remover colunas",
            nRowsSelected: "{0} linha(s) selecionada(s)",
            showColumnsTitle: "Mostrar Colunas",
            showColumnsAriaLabel: "Mostrar Colunas",
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportName: "Exportar para CSV",
            searchTooltip: "Filtro Rápido",
            searchPlaceholder: "Filtro Rápido",
        },
    }
}

const TEST:React.FC<any> = (props)=>{
    return <div style={{backgroundColor:'red'}}>{props.children}</div>
}

const Container:React.FC<any> = (props)=>{
    return <Paper elevation={0} {...props}/>;
}

const DaileonTable:React.FC<MaterialTableProps<object>> = (props)=>{
    const {options,...rest} = props;

    return <MaterialTable 
                options={{
                    padding: 'dense',
                    pageSize: 25,
                    pageSizeOptions: [25,50,100,500],
                    emptyRowsWhenPaging: false,
                    loadingType: 'linear',
                    ...options
                }}
                components={{
                    Container,
                    Toolbar: TEST,
                }}
                localization={LocalizationMap.ptBR}
                {...rest}
                />
};

export default DaileonTable;