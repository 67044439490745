import React, { useState, useRef, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

import { Paper, makeStyles, createStyles, Typography, Grid, Button, Divider } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import DaileonForm, { FormFields, DaileonFormButton } from '../../../main/component/daileonForm';
import Util from '../../../main/service/util';
import ValidationService from '../../../main/service/validationService';
import SelectField from '../../../main/component/selectField';
import ModalService from '../../../main/service/modalService';
import { CompatibleSwitch, CompatibleTextField } from '../../../main/component/daileonCommonInput';
import { DatePicker } from '../../../main/component/dateTimePicker';
import SelectTagsField from '../../../main/component/selectTagsField';
import { format } from 'date-fns';
import SelectCheckBoxField from '../../../main/component/selectCheckBoxField';

const useStyles = makeStyles( theme => createStyles({
        paper: {
            padding: theme.spacing(1),
        },
        divider: {
            margin: theme.spacing(1,0,1,0),
        },
        addButton: {
            color: theme.palette.common.white,
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[600],
            },
        },
        imageButton: {
            width: 160,
            backgroundColor: theme.palette.common.white,
        },
        imageSrc: {
            objectFit: 'cover',
            width: 160,
            height: 100,
            WebkitFilter: 'drop-shadow(2px 2px 2px #0006)',
            filter: 'drop-shadow(2px 2px 2px #0006)',
        },
    }),
);

const NovaConsulta: React.FC<RouteComponentProps> = (props)=>{
    const classes = useStyles({});

    const [rotinaList,setRotinaList] = useState<any[]>([]);
    const formRef = useRef(null);

    const getDefaultForm = (rotinas):FormFields => [
        {label: "Rotina", name: "rotina", defaultValue: "",
            type: SelectField, props:{
                isSearchable: true,
                options: rotinas.map(v=>({label: v.descricao+(v.nome?" - "+v.nome:""), value: v.id})),
            },
            formatValue: v=>v&&v.value,
            validation: ValidationService.required,
        },
        //{label: "DEBUG", name: "debug", defaultValue:false, type: CompatibleSwitch },
    ];

    const [formFields,setFormFields] = useState<FormFields>(getDefaultForm(rotinaList));
    const [refresh,setRefresh] = useState<boolean>(false);

    const initialValues = props.location.state;

    const fetchRotinas = async ()=>{
        const loadingModal = ModalService.common.loading;
        ModalService.add(loadingModal);
        const [data] = await Util.backendRequestHandled('thunder/rotinaUsuario','GET');
        if(data) {
            setRotinaList(data.rotina);
            setFormFields(getDefaultForm(data.rotina));
        }
        ModalService.remove(loadingModal);

        //console.log("State",props.location.state);
        
        const form:DaileonForm = formRef.current;
        await form.clear(true);
        setRefresh(true);
    }

    useEffect(()=>{
        fetchRotinas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if(refresh){
            const form:DaileonForm = formRef.current;
            onFormUpdate(form,'rotina');
            //console.log("Refreshing",form.getValues());
            if(initialValues){
                Util.waitUntil(()=>Boolean(form.getFieldValue('preset'))).then(()=>{
                    //console.log("Updated",form.getValues());
                    form.handleChange('preset',initialValues.preset);
                });
            }
        } else {
            setRefresh(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    const send = async (data:any)=>{
        const {rotina, debug, preset, ...params} = data;
        const postData = {
            rotina: rotina,
            preset: preset,
            parametros: params,
        };
        console.log(postData);
        if(!debug){
            const [response,failed] = await Util.backendRequestHandled('thunder/requisicao','POST',postData,true,true,true);
            console.log(response);
            if(!failed){
                props.history.push('/consulta');
            }
        }
    }

    //------- FORM ------------
    const formButtons:DaileonFormButton[] = [
        {label: "Solicitar", isSubmit: true,
            props: {variant: "contained", color: "primary"},
            action: (form)=>{
                const allValid = form.validateAll();
                if(allValid){
                    const values = form.getValues();
                    send(values);
                } else {
                    form.highlightInvalid();
                }
            },
        },
    ]
    const onFormUpdate = (form:DaileonForm, fieldName:string)=>{
        if(fieldName==='rotina' || fieldName==='preset'){
            const selectedRotina = form.getFieldValue('rotina');
            if(selectedRotina) {
                let newForm:any = getDefaultForm(rotinaList);
                const rotina = rotinaList.find(v=>v.id===selectedRotina);
                const parametros = JSON.parse(rotina.parametros);

                newForm.push({append: <hr/>});
                newForm.push({append: <Typography variant='h6'>{"Parâmetros "+rotina.descricao+(rotina.nome?" - "+rotina.nome:"")}</Typography>});

                const presets = parametros.presets;
                const selectedPreset = form.getFieldValue('preset');
                const selectedPresetData = presets && selectedPreset && presets.find(p=>p.label===selectedPreset.value);
                const presetData = selectedPresetData && selectedPresetData.fields;
                if(presets && presets.length>0){
                    const allowedPresets = rotina.presets.split(',');
                    //const custom = {label: "Personalizado",value: -1}; // Permite modo personalizado
                    const options = presets.map((v)=>({label: v.label, value: v.label})).filter(v=>allowedPresets.includes(v.value));
                    newForm.push({
                        label: "Pré-Definição", name: "preset", defaultValue: null,
                        type: SelectField, props:{
                            options: options,
                        }
                    });
                    if(!selectedPreset) Util.waitUntil(()=>Boolean(form.hasField('preset'))).then(()=>form.handleChange("preset", (initialValues?initialValues.preset:options[0])||options[0]));
                }

                const params = parametros.params;
                let valueList = null;
                let currentTitleIndex = null;
                params.forEach(param => {
                    if(param.title){
                        currentTitleIndex = newForm.length;
                        newForm.push({append: <b>{param.title}</b>, count: 0});
                        return;
                    }
                    let field:any = { label: param.label, name: param.name, defaultValue: param.default };
                    if(param.required) field.validation = ValidationService.required;
                    switch(param.type){
                        case "text":
                            field.type = CompatibleTextField;
                        break;
                        case "select":
                            field.type = SelectField;
                            field.props = {options: param.options};
                            field.formatValue = v=>v&&v.value;
                            field.formatInitialValue = val=>param.options.find(o=>o.value===val);
                        break;
                        case "multi_select":
                            field.type = SelectCheckBoxField;
                            field.props = {options: param.options, showSelectButtons:false};
                            field.formatValue = list=>(list && list.map(v=>v.value).join(';'))||"";

                            field.formatInitialValue = val=>param.options.find(o=>o.value===val);
                            field.formatInitialValue = val=>(val && val.split(';').map(v=>param.options.find(o=>o.value===v)));
                        break;
                        case "bool":
                            field.type = CompatibleSwitch;
                        break;
                        case "date":
                            field.type = DatePicker;
                            field.formatValue = v=> v instanceof Date ? format(v, 'dd/MM/yyyy') : v || "";
                        break;
                        case "list":
                            field.type = SelectTagsField;
                            field.props = {strictInput: false, showIndicator: false};
                            field.formatValue = list=>(list && list.map(v=>v.value).join(';'))||"";
                            field.formatInitialValue = val=>(val && val.split(';').map(v=>({label: v, value: v})));
                        break;
                        case "hidden":
                            field.type = CompatibleTextField;
                            field.props = {disabled:true};
                            field.hidden = true;
                        break;
                        default:
                            field.type = CompatibleTextField;
                    }
                    if(presetData){
                        const presetValue = presetData.find(p=>p.hasOwnProperty(param.name));
                        if(presetValue){
                            if(!valueList) valueList = {};
                            valueList[param.name] = presetValue[param.name];
                            field.props = {...field.props, disabled:true};
                            field.hidden = true;
                        }
                    }
                    newForm.push(field);
                    if(currentTitleIndex && !field.hidden) newForm[currentTitleIndex].count++;
                });
                form.clearFields(params.map(p=>p.name));
                if(valueList) Util.waitFor(1).then(()=>form.setValues(valueList)); // Espera 100 ms pra limpar o form
                // Remove titulos sem campos
                newForm = newForm.filter(f=>f.count!=null ? f.count>0 : true);
                setFormFields(newForm);
            } else {
                setFormFields(getDefaultForm(rotinaList));
            }
        }
    }

    return <Paper className={classes.paper}>
            <Grid container direction='row' justify='space-between' alignItems='center'>
                <Typography variant='h5'>{"Nova Consulta"}</Typography>
                <Button variant='contained' color='default' onClick={()=>props.history.goBack()}>Voltar</Button>
            </Grid>
            <Divider className={classes.divider}/>
            
            {/* {rotinaList.map((r,k) => (
                <Button key={k} variant='contained' focusRipple className={classes.imageButton}>
                    <div>
                        <img className={classes.imageSrc} draggable={false} src={r.img} alt={r.descricao}/>
                        <div><Typography variant='subtitle1' style={{textTransform:'none'}}>{r.descricao}</Typography></div>
                    </div>
                </Button>
            ))} */}

            <DaileonForm ref={formRef}
                fields={formFields}
                buttons={formButtons}
                onChange={onFormUpdate}
                initialValues={initialValues}
                />
        </Paper>;
}

export default NovaConsulta;