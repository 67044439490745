import React from 'react';
import { ModalSimplaItem } from '../component/modalSimpla';

import { CircularProgress, Avatar, Paper, Button, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import * as Icons from '@material-ui/icons';

export type ModalItem = ModalSimplaItem;

const commonIconSize = 50;
const commonAvatarSize = 60;

export default class ModalService {
    private static queue:ModalItem[] = [];
    private static listeners:Function[] = [];

    // Define modais utilitarias
    public static common = {
        success: {content: <Avatar className="nooutline" style={{backgroundColor: "white", width: commonAvatarSize, height: commonAvatarSize, margin: '0 auto'}}>
            <Icons.Check className="icon-pop nooutline" style={{width: commonIconSize, height: commonIconSize, color: "#0A0"}}/>
        </Avatar>, closeOnClick: true},
        loading: {content: <Avatar className="nooutline" style={{backgroundColor: "white", width: commonAvatarSize, height: commonAvatarSize, margin: '0 auto'}}>
            <CircularProgress size={commonIconSize} thickness={3} color="primary"/>
        </Avatar>, closeOnClick: false},
        error: (err)=>{
            let _modal = {content: null, closeOnClick: true};
            _modal.content = <>
                    <Avatar className="nooutline" style={{backgroundColor: "white", width: commonAvatarSize, height: commonAvatarSize, margin: '0 auto'}}>
                        <Icons.Close className="icon-pop nooutline" style={{width: commonIconSize, height: commonIconSize, color: "#A00"}}/>
                    </Avatar>
                    { err &&
                        <Paper style={{marginTop:'-30px',padding: '10px', paddingTop: '35px'}}>
                            <div>
                                <b style={{whiteSpace: 'pre-line'}}>{err}</b>
                            </div>
                            <div style={{marginTop: '10px'}}>
                                <Button color="secondary" fullWidth onClick={()=>ModalService.remove(_modal)}>OK</Button>
                            </div>
                        </Paper>
                    }
                </>;
            return _modal;
        },
        confirm: (title,content,action,okText="OK",cancelText="Cancelar",okColor:any='primary')=>{
            let _modal = {closeOnClick: false, content:null};
            _modal.content = <Paper>
                                { title && <DialogTitle>{title}</DialogTitle>}
                                <DialogContent>{content}</DialogContent>
                                <DialogActions>
                                    <Button color={okColor} onClick={()=>{action(true); ModalService.remove(_modal);}}>{okText}</Button>
                                    <Button color="default" onClick={()=>{action(false); ModalService.remove(_modal);}}>{cancelText}</Button>
                                </DialogActions>
                            </Paper>;
            return _modal;
        }
    };
    //--------------------------
    
    static popoutError(errors:any){
        const displayErrors = errors || [{desc:"Erro inexperado, tente novamente."}];
        const errMessage = displayErrors.map(e => e.desc+(e.code!=null?" (cod. "+e.code+")":"")).join('\n');
        const errModal = this.common.error(errMessage);
        this.add(errModal);
    }

    static getQueue(){
        return this.queue;
    }

    static add(item:ModalItem){
        this.queue.push(item);
        this.executeCallbacks();
    }

    static remove(item:ModalItem){
        this.queue = this.queue.filter(i=>i!==item);
        this.executeCallbacks();
    }

    static registerCallback(func:Function){
        this.listeners.push(func);
    }

    static executeCallbacks(){
        this.listeners.forEach(f=>f());
    }
}