import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import { fade, makeStyles, createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Chip, AppBar, Toolbar, IconButton, Typography, Badge, Menu, MenuItem, List, ListItem, ListItemIcon, ListItemText, Divider, Drawer, Hidden, CssBaseline, Slide, ClickAwayListener, Dialog, Tooltip, Icon, Collapse, useMediaQuery, ExpansionPanel as MuiExpansionPanel, ExpansionPanelSummary as MuiExpansionPanelSummary, ExpansionPanelDetails as MuiExpansionPanelDetails, ExpansionPanelActions, Button, Paper, DialogContentText, DialogContent, DialogTitle, DialogActions, Avatar } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';

import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import MenuSearch from './../component/menuSearch';
import MarkDown from '../../modules/markdown/component/markdown';

// Icones
import * as Icons from '@material-ui/icons';

import AuthService from '../../modules/auth/service/authService';
import NotificationService, { INotification } from './../service/notificationService';
import { orange } from '@material-ui/core/colors';
import ModalService from '../service/modalService';
import { ModalSimplaItem } from '../component/modalSimpla';

import Logo from '../../media/logo64.png';

const drawerWidth = 240;
const foldedDrawerWidth = 60;
const drawerWidthNotif = 400;

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        avatar: {
            margin: '0 10px 0 0',
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        appBarNotif: {
            position: 'relative',
        },
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
            display: 'block',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        titleNotif: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            marginRight: theme.spacing(2),
            marginLeft: 0,
            transition: 'background-color 100ms',
            width: '100%',
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            [theme.breakpoints.up('sm')]: {
                transition: 'background-color 100ms',
                marginLeft: theme.spacing(3),
                width: 'auto',
                [theme.breakpoints.up('md')]: {
                    position: 'absolute',
                    left: drawerWidth,
                }
            },
        },
        searchIcon: {
            width: theme.spacing(5),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        searchInput: {
            padding: theme.spacing(0, 0, 0, 7),
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 250,
            },
        },
        searchInputFocused: {
            padding: theme.spacing(0, 0, 0, 7),
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 400,
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        menu: {
            width: 'auto',
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth - 1,
            },
        },
        menuNotif: {
            width: 'auto',
            [theme.breakpoints.up('sm')]: {
                width: drawerWidthNotif - 1,
            },
        },
        drawer: {
            [theme.breakpoints.up('md')]: {
                width: foldedDrawerWidth,
                flexShrink: 0,
            },
            [theme.breakpoints.up('lg')]: {
                width: drawerWidth,
            }
        },
        drawerPaper: {
            width: 'auto',
            [theme.breakpoints.only('sm')]: {
                width: drawerWidth,
            },
            [theme.breakpoints.only('md')]: {
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
                width: foldedDrawerWidth,
                '&:hover': {
                    width: drawerWidth,
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    boxShadow: theme.shadows[5],
                }
            },
            [theme.breakpoints.up('lg')]: {
                width: drawerWidth,
            }
        },
        drawerPaperNotif: {
            width: drawerWidthNotif,
        },
        toolbar: theme.mixins.toolbar,
        content: {
            overflow: 'auto',
            //height: '-webkit-fill-available',
            height: '100vh',
            flexGrow: 1,
            padding: theme.spacing(1),
        },

        listItem_depth_1: {
            '&.Mui-selected': {
                // backgroundColor: theme.palette.primary.main,
                '&:hover': {
                    // backgroundColor: theme.palette.primary.light,
                },
                '& .MuiListItemIcon-root': {
                    // color: theme.palette.primary.contrastText,
                },
            }
        },
        listItem_depth_2: {
            backgroundColor: theme.palette.grey[100],
            '&.Mui-selected': {
                // backgroundColor: theme.palette.primary.main,
                '&:hover': {
                    // backgroundColor: theme.palette.primary.light,
                }
            }
        },
        listItem_depth_3: {
            backgroundColor: theme.palette.grey[200],
            '&.Mui-selected': {
                // backgroundColor: theme.palette.primary.main,
                '&:hover': {
                    // backgroundColor: theme.palette.primary.light,
                }
            }
        },
        expansionHeading: {
            fontSize: theme.typography.pxToRem(15),
            flexGrow: 1,
        },
        expansionSecondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        expansionNew: {
            backgroundColor: orange[100],
        },
        userBlock: {
            padding: theme.spacing(0),
            margin: theme.spacing(0, 1),
        },
        userIcon: {
            paddingTop: 0,
            marginBottom: -4,
            height: 18,
        },
        userChipRoot1: {
            fontSize: theme.typography.caption.fontSize,
            width: '100%',
            justifyContent: 'start',
            borderRadius: '5px 5px 0px 0px',
            '& .MuiChip-label': {
                userSelect: 'auto',
            }
        },
        userChipRoot2: {
            fontSize: theme.typography.caption.fontSize,
            width: '100%',
            justifyContent: 'start',
            borderRadius: '0px 0px 5px 5px',
            '& .MuiChip-label': {
                userSelect: 'auto',
            }
        },
        userChipColorPrimary: {
            backgroundColor: theme.palette.primary.light,
        },
    }),
);

const ExpansionPanel = withStyles(theme => ({
    root: {
        border: 0,
        boxShadow: 'none',
        borderRadius: 0,
        transition: theme.transitions.create('background-color', { duration: 100 }),
        '&:last-child': {
            borderRadius: 0,
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
        },
        '&:first-child': {
            borderRadius: 0,
            borderTop: '1px solid rgba(0, 0, 0, .125)',
        },
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
        },
    },
    expanded: {
        boxShadow: theme.shadows[5],
        backgroundColor: theme.palette.grey[100],
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
        '&:last-child': { borderBottom: 0 },
        '&:first-child': { borderTop: 0 },
    },
}))(MuiExpansionPanel);
const ExpansionPanelSummary = withStyles({
    root: {
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);
const ExpansionPanelDetails = withStyles(theme => ({
    root: {

    },
}))(MuiExpansionPanelDetails);

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface AppNavProps {
    hideNav?: boolean;
    logout: Function;
    installEvent: any;
    setInstallEvent: any;
}

const AppNav: React.FC<AppNavProps & RouteComponentProps> = (props) => {
    const theme = useTheme();
    const classes = useStyles(props);

    const [menuItens, setMenuItens] = React.useState<any[]>([]);
    const [searchFocused, setSearchFocus] = React.useState(false);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuOpened, setMenuOpened] = React.useState(false);
    const [showNotifications, setShowNotifications] = React.useState(false);
    const compactToolbarOpened = Boolean(mobileMoreAnchorEl);

    const [notifications, setNotifications] = React.useState<Array<INotification>>([]);

    NotificationService.registerCallback(() => setNotifications(NotificationService.getNotifications()));

    const [openedNotification, setOpenedNotification] = React.useState(-1);

    const sessionData = AuthService.getData();
    const accessData = sessionData.acesso || [];
    const userInfo = sessionData.user || {nome: 'Indefinido',nivel: 'Indefinido'};
    React.useEffect(() => {
        // Monta o menu estruturado
        let menuItemList = [];
        for (let i = 0; i < accessData.length; i++) {
            let item = accessData[i];
            //if(!item.displayMenu) continue;

            let existing_item: any;
            let parent: any;

            const recFindItem = (val) => {
                if (val.id === item.id) {
                    existing_item = val;
                    return true;
                }
                if (val.subItens) return val.subItens.some(recFindItem);
            }
            menuItemList.some(recFindItem);

            if (item.dependencia) {
                // Tenta achar a dependencia
                const recFindParent = (val) => {
                    if (val.id === item.dependencia) {
                        parent = val;
                        return true;
                    }
                    if (val.subItens) return val.subItens.some(recFindParent);
                }
                menuItemList.some(recFindParent);
                // Cria dependencia se nao existir
                if (!parent) {
                    parent = {
                        id: item.dependencia,
                        subItens: [],
                        opened: false,
                    };
                    menuItemList.push(parent);
                }
            }
            // Se o item nao exite, cria no parent ou no root
            if (!existing_item) {
                if (parent) {
                    if (!parent.subItens) {
                        parent.subItens = [];
                        parent.opened = false;
                    }
                    parent.subItens.push({ ...item });
                }
                else menuItemList.push({ ...item });

            } else { // Se existe, aplica no parent ou no root
                // Extende o item com mais informacoes
                existing_item = { ...existing_item, ...item };
                // move pro parent certo ou aplica no root
                if (parent) {
                    menuItemList = menuItemList.filter((val) => val.id !== item.id);
                    parent.subItens.push({ ...existing_item });
                } else {
                    let idx = menuItemList.findIndex((val) => val.id === item.id);
                    menuItemList[idx] = existing_item;
                }
            }

        }
        const recSort = (a,b)=>{
            if(a.subItens) a.subItens.sort(recSort);
            if(b.subItens) b.subItens.sort(recSort);
            return (''+a.id).localeCompare(b.id);
        }
        menuItemList.sort(recSort);
        // Seta o novo menu
        setMenuItens(menuItemList);
    }, [accessData]);

    const isMedium = useMediaQuery(theme.breakpoints.only('md'));
    React.useEffect(() => {
        if (isMedium) {
            //console.log("Menu update: ",menuOpened);
        }
    }, [menuOpened, isMedium]);

    const newNotificationsCount = notifications.filter(item => !item.visualizado && item.ativo).length;

    function handleMobileMenuClose() {
        setMobileMoreAnchorEl(null);
    }
    function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
        setMobileMoreAnchorEl(event.currentTarget);
    }
    function handleNotifButton() {
        handleMobileMenuClose();
        setShowNotifications(true);
    }
    function handleExitButton() {
        handleMobileMenuClose();
        props.logout();
    }
    function handleCloseNotif() {
        setOpenedNotification(-1);
        setShowNotifications(false);
    }
    function handleInstallButton() {
        let info:ModalSimplaItem;
        info = {
            content: <Paper>
                <DialogTitle id="alert-dialog-title">{"Instalar App Node"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Para instalar o sistema no seu dispositivo, simplesmente clique ou toque em instalar abaixo.<br/>
                    <small style={{color: theme.palette.text.hint}}>Disponível para Windows, Mac, Linux, Android e IOS.</small>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>ModalService.remove(info)} color="default">
                    Agora não
                </Button>
                <Button onClick={()=>{
                        props.installEvent.prompt();
                        props.installEvent.userChoice.then((choiceResult) => {
                            if (choiceResult.outcome === 'accepted') {
                                console.log('USER CLICKED INSTALL');
                            } else {
                                console.log('USER DISMISSED INSTALL');
                            }
                            props.setInstallEvent(null);
                        });
                        ModalService.remove(info);
                    }} color="primary" autoFocus>
                    Instalar
                </Button>
                </DialogActions>
            </Paper>,
            closeOnClick: true,
        };
        ModalService.add(info);
    }

    function handleMenuClick(item: any) {
        if (item.subItens) {
            let menuItens_copy = [...menuItens];

            let arr_item: any;
            const recFindItem = (val) => {
                if (val.id === item.id) {
                    arr_item = val;
                    return true;
                }
                if (val.subItens) return val.subItens.some(recFindItem);
            }
            menuItens.some(recFindItem);

            arr_item.opened = !arr_item.opened;
            setMenuItens(menuItens_copy);
        }
        if (item.path) {
            props.history.push(item.path);
            if (!isMedium) setMenuOpened(false);
        }
    }

    const renderMenuItem = (item: any, index: number, depth: number = 1) => {
        if (!item.displayMenu) return;
        const hasSubItens = item.subItens && item.subItens.some(i=>i.displayMenu);
        const opened = hasSubItens && item.opened && (menuOpened || !isMedium);
        const isSelected = Boolean(item.path) && props.location.pathname.includes(item.path);
        return <React.Fragment key={index}>
            <ListItem className={(depth > 1 ? (depth > 2 ? classes.listItem_depth_3 : classes.listItem_depth_2) : classes.listItem_depth_1)} selected={isSelected} button style={{ paddingLeft: theme.spacing(depth * 2) }} onClick={() => handleMenuClick(item)}>
                <ListItemIcon><Icon>{item.icon}</Icon></ListItemIcon>
                <ListItemText primary={item.label} />
                {hasSubItens && (opened ? <Icons.ExpandLess color='action' /> : <Icons.ExpandMore color='action' />)}
            </ListItem>
            {hasSubItens &&
                <Collapse in={opened} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {item.subItens.map((subItem: any, sidx: number) => renderMenuItem(subItem, sidx, depth + 1))}
                    </List>
                </Collapse>
            }
        </React.Fragment>;
    }

    const menuList = (
        <div className={classes.menu} role="presentation" onKeyDown={() => setMenuOpened(false)}>
            <List>
                {menuItens.map((_item, _idx) => renderMenuItem(_item, _idx))}
            </List>
            <Divider />
        </div>
    );

    let menuOptions: Array<{ label: string, value: string }> = [];
    const getMenuOptions = (item: any, prefix: string = "") => {
        if (item.displayAcessoRapido!==true) return;
        if (item.path) menuOptions.push({ label: prefix + item.label, value: item.path });
        if (item.subItens) {
            item.subItens.forEach((_val) => getMenuOptions(_val, prefix + item.label + ' > '));
        }
    }
    menuItens.forEach((_val) => getMenuOptions(_val));

    const renderMobileSubmenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={compactToolbarOpened}
            onClose={handleMobileMenuClose}>

            <MenuItem disabled style={{ opacity: 1 }}>
                <div className={classes.userBlock}>
                    <div>
                        <Chip classes={{ root: classes.userChipRoot1 }}
                            label={userInfo.nome} variant="default" size="small" icon={<Icons.Person />} />
                    </div>
                    <div>
                        <Chip classes={{ root: classes.userChipRoot2 }}
                            label={userInfo.nivel} variant="default" size="small" icon={<Icons.VpnKey />} />
                    </div>
                </div>
            </MenuItem>
            <MenuItem onClick={handleNotifButton}>
                <IconButton color="inherit">
                    <Badge badgeContent={newNotificationsCount} color="secondary"> <Icons.Notifications /> </Badge>
                </IconButton>
                <p>Notificações</p>
            </MenuItem>
            {Boolean(props.installEvent) && <MenuItem onClick={handleInstallButton}>
                <IconButton color="inherit"> <Icons.GetAppRounded /> </IconButton>
                <p>Instalar App</p>
            </MenuItem>}
            <MenuItem onClick={handleExitButton}>
                <IconButton color="inherit"> <Icons.ExitToApp /> </IconButton>
                <p>Sair</p>
            </MenuItem>
        </Menu>
    );
    const handleNotificationExpansion = (item: INotification, index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setOpenedNotification(isExpanded ? index : -1);
    };

    const notificationsDrawer = (
        <div className={classes.menuNotif} role="presentation" onKeyDown={handleCloseNotif}>
            <List subheader={
                <Typography style={{ margin: theme.spacing(2) }} variant="h6">{'Notificações'}</Typography>
            }>
                 {notifications.map((item, index) => {
                    if (!item.ativo) return null;
                    const wasRead = Boolean(item.visualizado);
                    return <ExpansionPanel className={wasRead ? '' : classes.expansionNew} key={index} expanded={index === openedNotification} onChange={handleNotificationExpansion(item, index)}>
                        <ExpansionPanelSummary>
                            <ListItemIcon><Icon>{item.icon}</Icon></ListItemIcon>
                            <Typography className={classes.expansionHeading}>{item.titulo}</Typography>
                            <Typography className={classes.expansionSecondaryHeading}>a {formatDistanceToNow(new Date(item.timestamp), { locale: ptBR, includeSeconds: true })}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <MarkDown source={item.conteudo} />
                        </ExpansionPanelDetails>
                        <ExpansionPanelActions>
                            <Button size="small" disabled={wasRead} color="primary"
                                onClick={() => { NotificationService.markAsRead(item); setOpenedNotification(-1); }}>
                                {wasRead ? `Lido a ${formatDistanceToNow(new Date(item.visualizado), { locale: ptBR, includeSeconds: true })}` : 'Marcar como lido'}
                            </Button>
                            <Button size="small" onClick={() => { NotificationService.hide(item); setOpenedNotification(-1); }}>Esconder</Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>;
                })}
            </List>
        </div>
    );

    const handleQuickAccessSelect = (option: any) => {
        props.history.push(option.value);
    }

    return <div className={classes.root}>
        <CssBaseline />
        <Slide direction="down" in={!props.hideNav} unmountOnExit>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" onClick={() => setMenuOpened(!menuOpened)}> <Icons.Menu /> </IconButton>

                    <Avatar className={classes.avatar} src={Logo}/>
                    <Typography className={classes.title} variant="h6" noWrap>
                        Extrator Node
                    </Typography>

                    <div className={classes.search}>
                        <div className={classes.searchIcon}> <Icons.Search /> </div>
                        <MenuSearch className={searchFocused ? classes.searchInputFocused : classes.searchInput}
                            options={menuOptions}
                            value={null}
                            placeholder={"Acesso Rápido..."}
                            onChange={handleQuickAccessSelect}
                            onMenuOpen={() => setSearchFocus(true)}
                            onMenuClose={() => setSearchFocus(false)} />
                    </div>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <div className={classes.userBlock}>
                            <div>
                                <Chip classes={{ root: classes.userChipRoot1, colorPrimary: classes.userChipColorPrimary }}
                                    label={userInfo.nome} variant="default" color="primary" size="small" icon={<Icons.Person />} />
                            </div>
                            <div>
                                <Chip classes={{ root: classes.userChipRoot2, colorPrimary: classes.userChipColorPrimary }}
                                    label={userInfo.nivel} variant="default" color="primary" size="small" icon={<Icons.VpnKey />} />
                            </div>
                        </div>
                        {/* </Paper> */}
                        {Boolean(props.installEvent) && <Tooltip title="Instalar App">
                            <IconButton color="inherit" onClick={handleInstallButton}>
                                <Icons.GetAppRounded />
                            </IconButton>
                        </Tooltip>}
                        <Tooltip title="Notificações">
                            <IconButton color="inherit" onClick={handleNotifButton}>
                                <Badge badgeContent={newNotificationsCount} color="secondary"> <Icons.Notifications /> </Badge>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Sair">
                            <IconButton edge="end" color="inherit" onClick={handleExitButton}> <Icons.ExitToApp /> </IconButton>
                        </Tooltip>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton color="inherit" onClick={handleMobileMenuOpen}>
                            <Badge badgeContent={newNotificationsCount} color="secondary"> <Icons.AccountCircle /> </Badge>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
        </Slide>

        {renderMobileSubmenu}

        {/* Notificacoes */}
        <Hidden smUp>
            <Dialog fullScreen open={showNotifications} onClose={handleCloseNotif} TransitionComponent={Transition}>
                <AppBar className={classes.appBarNotif}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.titleNotif}>Notificações</Typography>
                        <IconButton edge="end" color="inherit" onClick={handleCloseNotif} aria-label="Fechar"><Icons.Close /></IconButton>
                    </Toolbar>
                </AppBar>
                {notificationsDrawer}
            </Dialog>
        </Hidden>
        <Hidden xsDown>
            <Drawer anchor="right" variant="temporary" classes={{ paper: classes.drawerPaperNotif }} open={showNotifications} onClose={handleCloseNotif} ModalProps={{ hideBackdrop: true, style: { zIndex: 1199 } }}>
                <ClickAwayListener onClickAway={handleCloseNotif}>
                    <div>
                        <div className={classes.toolbar} />
                        {notificationsDrawer}
                    </div>
                </ClickAwayListener>
            </Drawer>
        </Hidden>


        {/* Side Menu */}
        <Slide direction="right" in={!props.hideNav} unmountOnExit>
            <nav className={classes.drawer}>
                <Hidden smDown>
                    <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open={menuOpened} onMouseEnter={() => setMenuOpened(true)} onMouseLeave={() => setMenuOpened(false)}>
                        <div className={classes.toolbar} />
                        {menuList}
                    </Drawer>
                </Hidden>
                <Hidden smUp>
                    <Drawer classes={{ paper: classes.drawerPaper }} anchor="top" variant="temporary" open={menuOpened} onClose={() => setMenuOpened(false)}>
                        {menuList}
                    </Drawer>
                </Hidden>
                <Hidden mdUp xsDown>
                    <Drawer classes={{ paper: classes.drawerPaper }} anchor="left" variant="temporary" open={menuOpened} onClose={() => setMenuOpened(false)}>
                        {menuList}
                    </Drawer>
                </Hidden>
            </nav>
        </Slide>

        <main className={classes.content}>
            <Slide direction="down" in={!props.hideNav} unmountOnExit><div className={classes.toolbar} /></Slide>
            {props.children}
        </main>
    </div>;
}

export default withRouter(AppNav);