const MarkdownExample:string = `

Plano de desenvolvimento
=============
---
Extrator Node
-------------
*Procure comentarios com **_[TODO_]** no código pra ver partes incompletas.*

### A fazer:
#### Geral
* Tecla para focar "Acesso Rapido", por exemplo "Q" (apenas ativo se nao estiver focando outro campo)
* Adicionar evento de sessao "DELETE" da classe de DB
* Tela: Sessoes
* Tela: Atividade de Sessoes
* Componente DForm: Campo de senha avançado (botao gerar aleatorio, analize de complexidade, botao copiar, botao ver senha)
* ~~Limitar quantidade de notificações pra mostrar (na view)~~
* ~~Informar erro correto de sessao inativa~~
* ~~Marcar a sessao como desativada ao ser inativada~~
* ~~Implementar notificacoes~~
* ~~Inserir campo de "nome real" do usuario pra display~~
* ~~Limpar cache ao deslogar~~
* ~~Tela: Display Acesso~~
* ~~Componente: Switch(on/off) compativel com o DForm~~
* ~~Tela: Cadastro Acesso~~
* ~~Tela: Display Nível~~
* ~~Tela: Cadastro Nível~~
* ~~Componente: Number Input compatível com DForm~~
* ~~Mostrar ERROS de VALIDACAO nos Inputs do DForm~~
* ~~Botao "Lembrar Login": Salvar usuario/senha encodados no local storage~~ ~~(Tira o botao na vdd)~~
* ~~Redirecionar login para primeira pagina acessivel na lista, e nao pro principal~~
* ~~Mostrar SubAcessos no acesso rápido (ex: Consultas > Nova Consulta)~~
* ~~Ordernar Items do Menu e do Acesso Rápido~~
* ~~Passar parametros escondidos na consulta~~
* ~~Passar ID da requisicao por GET no callback~~
* ~~Tela: Lista Usuario~~
* ~~Tela: Cadastro Usuario~~

#### Extrator
* Backend: Validar parametros da requisicao no backend, incluindo presets
* Colocar a tabela utilitaria "__util_numbers" em algum bagulho que cria essa table automatica pra nao dar zica inesperada no futuro
* DB: adicionar campo "acesso_ordem (int)" para ordenar botoes de acesso
* Tela: Lista Consulta (Lista operacoes ativas e realizadas + botao nova consulta + botao novo agendamento)
    * ~~Clonar consulta~~
    * ~~Baixar consulta (desativar em caso de erro)~~
    * ~~Detalhes (mostrar LOG BBCode)~~
    * ~~Filtros de consulta~~
    * ~~Arrumar Notificação de concluído~~
    * Colorir status
    * ~~Popup seleção de export type~~
    * Agendamento de rotinas
* Tela: Nova Consulta / Agendamento (adicionar ou editar existente)
    * ~~Novo~~
    * Componente: Botões quadrados com imagem/icone
    * Popup com tutorial da rotina
    * Ultimos valores usados no campo
    * ~~Init form (quando clonado)~~
* Tela: Admin > Rotinas (adicionar e configurar rotinas)
* Dashboard: 
    * Mostrar operacoes ativas;
    * Previa de agendamentos;
    * Ultimas operacoes realizadas;
    * Botoes: "nova operacao", "novo agendamento";
* ~~Definir acesso de usuarios a Presets individuais das rotinas~~
* ~~Backend: Estrutura de processamento de resultado~~
* ~~Backend: Descricao de status~~
* ~~DB: adicionar campo "acesso_acessoRapido" pra listar no acesso rapido~~
* ~~Presets de rotina~~

#### Bugs
* Data atual no dateTimePicker ta com timezone errado
* Mesmo apos perder conexao, fica recebendo mensagem de erro do request de login causado pelas notificacoes. (ainda acontece?)
* Clicar fora de notificaçao, mas ainda no drawer, fecha o Drawer, nao é pra fechar.
* Remover listeners quando mudar de pagina (no onWillUnmount)
* Abrir os items do menu pra mostrar o item da pagina atual
* Não pode usar '/' no meio da url de uma subpagina, pois buga os icones do MUI, se resolver isso da pra usar.
* ~~Erro de Charset Encoding convertendo CSV para XLS~~
* ~~Download nao funciona no LIVE~~
* ~~SAIR não funciona~~
`;

export default MarkdownExample;