import React from 'react';
import SelectField from './selectField';



const SelectTagsField: React.FC<any> = (props)=>{
    const {...rest} = props;
    return <SelectField 
            strictInput={true}
            showIndicator={true}
            hideSelectedOptions={true}
            backspaceRemovesValue={true}
            isMulti={true}
            closeMenuOnSelect={false}
            tabSelectsValue={false}
            isSearchable={true}
            isClearable={true}
            fetchOnType={false}
            multiMode={'tag'}
            where={'in'}
            {...rest}/>;
}

export default SelectTagsField;