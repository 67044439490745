
export default class ValidationService {
    static password(value:string):[boolean, string?]{
        if(value.length<3) return [false,"* Senha muito curta"];
        return [true];
    }
    static required(value:any):[boolean, string?]{
        const err:[boolean,string] = [false, "* Campo obrigatório"];
        switch(typeof(value)){
            case "string": if(!value || value.length<=0) return err;
            break;
            default: if(!value) return err;
        }
        return [true];
    }
    static cpf(value:string):[boolean, string?]{
        const err:[boolean,string] = [false, "* CPF inválido"];
        const cpf = value.replace(/[^\d]+/g,'');
        if(cpf.length !== 11) return err;
        // Se todos os characteres forem iguais, é um cpf invalido q passa na regra.
        let anydiff=false;
        for(let i=1;i<cpf.length;i++) if(cpf[i]!==cpf[0]) {anydiff = true; break;}
        if(!anydiff) return err;
        // Valida 1o digito
        let add = 0;
        for (let i=0; i < 9; i ++) add += parseInt(cpf.charAt(i)) * (10 - i);
        let rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)	rev = 0;
        if (rev !== parseInt(cpf.charAt(9))) return err;
        // Valida 2o digito
        add = 0;
        for(let i=0;i<10;i++) add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)	rev = 0;
        if (rev !== parseInt(cpf.charAt(10))) return err;

        return [true];
    }
}