import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import DaileonTable, { TableColumn, setColumnSize } from '../../main/component/daileonTable';

import { Paper, makeStyles, createStyles, Typography, Divider, Grid, IconButton, Menu, MenuItem } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Icon } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Util from '../../main/service/util';
import ModalService from '../../main/service/modalService';
import ProtectedButton from '../auth/component/protectedButton';
import AuthService from './../auth/service/authService';

const useStyles = makeStyles( theme => createStyles({
        paper: {
            padding: theme.spacing(1),
        },
        divider: {
            margin: theme.spacing(1,0,1,0),
        },
        addButton: {
            color: theme.palette.common.white,
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[600],
            },
        },
    }),
);

const Acesso: React.FC<RouteComponentProps> = (props)=>{
    const PATH_CADASTRO = "/acesso-cadastro";

    const classes = useStyles({});

    const [dataList,setDataList] = useState<any[]>([]);
    const [loading,setLoading] = useState<boolean>(false);
    const [actionAnchors,setActionAnchors] = useState<any>({});

    React.useEffect(()=>{
        fetchData();
    },[]);

    const fetchData = async ()=>{
        setLoading(true);
        const [data,] = await Util.backendRequestHandled('auth/acesso','GET');
        if(data) setDataList(data.acesso);
        console.log(data.acesso);
        setLoading(false);
    }

    //------- DATATABLE ------------

    const tableColumns:TableColumn[] = [
        { title: 'Codigo', field: 'id', ...setColumnSize(30,null,{textAlign:'center'}) },
        { title: 'Icone', field: 'icon', render: rowData => <Icon>{rowData.icon}</Icon>, ...setColumnSize(30,null,{textAlign:'center'}) },
        { title: 'Nome', field: 'label' },
        { title: 'Acesso', field: 'path' },
        { title: 'Menu', field: 'displayMenu', type: 'boolean', ...setColumnSize(30,null,{textAlign:'center'}) },
        { title: 'Acesso Rápido', field: 'displayAcessoRapido', type: 'boolean', ...setColumnSize(30,null,{textAlign:'center'}) },
        { title: 'Dependencia', field: 'dependencia', render: rowData => {
            const e = dataList.find(i=>i.id===rowData.dependencia);
            return e?e.label:"";
        }},
        { title: 'Ações', hidden: !AuthService.checkPermission(PATH_CADASTRO), ...setColumnSize(30,{textAlign:'right'},{textAlign:'right'}), render: rowData => {
            const anchor = actionAnchors[rowData.id] || null;
            const handleClick = evt=>setActionAnchors({...actionAnchors, [rowData.id]:evt.currentTarget});
            const clickEdit = ()=>{
                // Converte o formato de dependencia pro selectBox
                const e = dataList.find(i=>i.id===rowData.dependencia);
                props.history.push(PATH_CADASTRO, {...rowData,dependencia: e && {label: e.label, value: rowData.dependencia}});
                handleClose();
            };
            const clickRemove = ()=>{
                const confirmModal = ModalService.common.confirm(
                    'Remover acesso "'+rowData.label+'" ?',
                    'Tem certeza que deseja excluir o acesso "'+rowData.label+'"?',
                    async ok=>{
                        if(ok){
                            await Util.backendRequestHandled('auth/acesso?id='+rowData.id,'DELETE',null,true,true,true);
                            fetchData();
                        }
                    },'EXCLUIR','Cancelar','secondary'
                );
                ModalService.add(confirmModal);
                handleClose();
            };
            const handleClose = ()=>setActionAnchors({...actionAnchors, [rowData.id]:null});
            return <>
                <IconButton aria-label="actions" onClick={handleClick}>
                    <Icons.MoreVert/>
                </IconButton>
                <Menu
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={clickEdit}>Editar</MenuItem>
                    <MenuItem onClick={clickRemove}>Remover</MenuItem>
                </Menu>
            </>;
        }},
    ];

    return <Paper className={classes.paper}>
            <Grid container direction='row' justify='space-between' alignItems='center'>
                <Typography variant='h5'>Rotas de Acesso</Typography>
                <ProtectedButton path={PATH_CADASTRO} variant='contained' color='inherit' className={classes.addButton} onClick={()=>props.history.push(PATH_CADASTRO)}>Novo Acesso</ProtectedButton>
            </Grid>
            <Divider className={classes.divider}/>
            <DaileonTable
                title="Definições de Acesso"
                columns={tableColumns}
                options={{}}
                isLoading={loading}
                data={dataList||[]}
                />
        </Paper>;
}

export default Acesso;