import React, { HTMLAttributes } from 'react';

import PropTypes from 'prop-types';

import { CSSProperties, makeStyles } from '@material-ui/styles';
import { useTheme, Typography, MenuItem, Chip, Paper, createStyles, Theme, InputBase } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import { emphasize } from '@material-ui/core/styles';


import Select from 'react-select';
import { NoticeProps, MenuProps } from 'react-select/src/components/Menu';
import { ControlProps } from 'react-select/src/components/Control';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { IndicatorProps } from 'react-select/src/components/indicators';


export interface OptionType { label:string; value:string; }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'flex',
      padding: 0,
      height: 'auto',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      bottom: 6,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
    indicator: {
      height: theme.spacing(5),
    }
  }),
);

function NoOptionsMessage(props: NoticeProps<OptionType>) {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    );
  }
  
  NoOptionsMessage.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
  } as any;
  
  type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;
  
  function inputComponent({ inputRef, ...props }: InputComponentProps) {
    return <div ref={inputRef} {...props} />;
  }
  
  inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  } as any;
  
  function Control(props: ControlProps<OptionType>) {
    const {children, innerProps, innerRef, selectProps: { classes, TextFieldProps }} = props;
  
    return (
      <InputBase
        fullWidth
        inputComponent={inputComponent}
        inputProps={{
            className: classes.input,
            ref: innerRef,
            children,
            ...innerProps,
        }}
        {...TextFieldProps}
      />
    );
  }
  
  Control.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    selectProps: PropTypes.object.isRequired,
  } as any;
  
  function Option(props: OptionProps<OptionType>) {
    return (
      <MenuItem
        ref={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
      >
        <span style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          display : 'flex',
          flexDirection : 'row-reverse',
          alignItems: 'flex-start',
          textOverflow: 'ellipsis',
        }}>{props.children}</span>
      </MenuItem>
    );
  }
  
  Option.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
  } as any;
  
  function Placeholder(props: PlaceholderProps<OptionType>) {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.placeholder}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    );
  }
  
  Placeholder.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
  } as any;
  
  function SingleValue(props: SingleValueProps<OptionType>) {
    return (
      <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
        {props.children}
      </Typography>
    );
  }
  
  SingleValue.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
  } as any;
  
  function ValueContainer(props: ValueContainerProps<OptionType>) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
  }
  
  ValueContainer.propTypes = {
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
  } as any;
  
  function MultiValue(props: MultiValueProps<OptionType>) {
    return (
      <Chip
        tabIndex={-1}
        label={props.children}
        // className={clsx(props.selectProps.classes.chip, {
        //   [props.selectProps.classes.chipFocused]: props.isFocused,
        // })}
        className={props.selectProps.classes.chip}
        onDelete={props.removeProps.onClick}
        deleteIcon={<Icons.Cancel {...props.removeProps} />}
      />
    );
  }
  
  function IndicatorsContainer(props:IndicatorProps<OptionType>){
      return <div className={props.selectProps.classes.indicator}/>;
  }

  MultiValue.propTypes = {
    children: PropTypes.node,
    isFocused: PropTypes.bool,
    removeProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired,
  } as any;
  
  function Menu(props: MenuProps<OptionType>) {
    return (
      <Paper elevation={5} className={props.selectProps.classes.paper} {...props.innerProps}>
        {props.children}  
      </Paper>
    );
  }
  
  Menu.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
  } as any;

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    IndicatorsContainer,
};

const MenuSearch: React.FC<any> = (props)=>{
    const classes = useStyles(props);
    const theme = useTheme();
    const selectStyles = {
        input: (base: CSSProperties) => ({
          ...base,
          color: theme.palette.text.primary,
          '& input': {
            font: 'inherit',
          },
        }),
      };
    
    return <Select classes={classes} styles={selectStyles} components={components} {...props} noOptionsMessage={(inputValue)=>"Nada encontrado"}/>;
};

export default MenuSearch;