import Util from "./util";
import AuthService from './../../modules/auth/service/authService';
import ModalService from "./modalService";

export interface INotification {
    id:number;
    titulo:string;
    icon?:string;
    conteudo:string;
    timestamp:number;
    visualizado?:number|null;
    ativo:boolean;
}

export default class NotificationService {
    private static fetchInterval = 15; // Segundos entre cada fetch de atualizacao

    private static intervalHandle = null;
    private static listeners:Function[] = [];
    private static notifications:Array<INotification> = [];

    private static sort(){
        this.notifications.sort((a,b)=>b.timestamp-a.timestamp);
    }

    public static async fetch(){
        if(!AuthService.authenticated()) return;
        const response = await Util.backendRequest('base/notificacao','GET');
        const responseData = response.data || {};
        if(response.status!==200){
            const [success] = await AuthService.fetchSession();
            if(!success) if(responseData.error) ModalService.popoutError(responseData.error);
        }
        if(responseData.success){
            this.notifications = responseData.notificacoes;
            this.sort();
            this.executeCallbacks();
        }
    }
    public static beginInterval(){
        if(this.intervalHandle!=null) this.stopInterval();
        this.fetch();
        this.intervalHandle = setInterval(async ()=>{
            this.fetch();
        },this.fetchInterval*1000);
    }
    public static stopInterval(){
        clearInterval(this.intervalHandle);
        this.intervalHandle = null;
    }

    public static getNotifications(){
        return this.notifications;
    }
    //
    public static notify(item:INotification){
        this.notifications.push(item);
        this.sort();
        this.executeCallbacks();
    }
    public static async markAsRead(item:INotification){
        // Muda na memória
        let idx = this.notifications.indexOf(item);
        if(idx>-1){
            this.notifications[idx].visualizado = Date.now();
            const postData = {
                action: 'set_viewed',
                id: item.id,
            };
            await Util.backendRequest('base/notificacao','POST',postData);
        } else {
            console.log("Notification not found in original array: ",item);
        }
        this.executeCallbacks();
    }
    public static async hide(item:INotification){
        // Muda na memoria só pra testar
        let idx = this.notifications.indexOf(item);
        if(idx>-1){
            this.notifications[idx].ativo = false;
            const postData = {
                action: 'deactivate',
                id: item.id,
            };
            await Util.backendRequest('base/notificacao','POST',postData);
        } else {
            console.log("Notification not found in original array: ",item);
        }
        this.executeCallbacks();
    }

    static registerCallback(func:Function){
        this.listeners.push(func);
    }
    static executeCallbacks(){
        this.listeners.forEach(f=>f());
    }
}