import React from 'react';
import { RouteComponentProps } from 'react-router';

import { Paper, makeStyles, createStyles, Typography, Grid, Button, Divider } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import DaileonForm, { FormFields, DaileonFormButton } from '../../main/component/daileonForm';
import { CompatibleTextField } from '../../main/component/daileonCommonInput';
import SelectField from '../../main/component/selectField';
import Util from '../../main/service/util';
import ValidationService from '../../main/service/validationService';
import SelectCheckBoxField from '../../main/component/selectCheckBoxField';


const useStyles = makeStyles( theme => createStyles({
        paper: {
            padding: theme.spacing(1),
        },
        divider: {
            margin: theme.spacing(1,0,1,0),
        },
        addButton: {
            color: theme.palette.common.white,
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[600],
            },
        },
    }),
);

const CadastroUsuario: React.FC<RouteComponentProps> = (props)=>{
    const classes = useStyles({});

    const initialValues = props.location.state;
    const editCode = initialValues && initialValues.id;

    const save = async (data:any)=>{
        const postData = {
            action: editCode?'update':'insert',
            id: editCode,
            ...data,
        };
        const [,failed] = await Util.backendRequestHandled('base/usuario','POST',postData,true,true,true);
        if(!failed){
            props.history.push('/usuario');
        }
    }

    //------- FORM ------------
    const formFields:FormFields = [
        {label: "Usuário", name: "nome", defaultValue: "", grid:{ sm:6 }, 
            type: CompatibleTextField, props:{},
            validation: ValidationService.required,
        },
        {label: "Nome", name: "nomeDisplay", defaultValue: "", grid:{ sm:6 },
            type: CompatibleTextField, props:{ },
        },
        {label: "Senha", name: "senha", defaultValue: "",
            type: CompatibleTextField, props:{ },
            validation: !editCode && ValidationService.required,
        },
        {label: "Nível", name: "nivel", defaultValue: "",
            type: SelectField, props:{
                dataURI:"auth/nivel", dataPath: "nivel", optionLabelPath: "nome", optionValuePath: "id",
                isSearchable: true,
            },
            validation: ValidationService.required,
            formatValue: v=>v.value,
            formatInitialValue: (v,i)=>({label: i.nivel_nome, value: v}),
        },
        {label: "Rotinas Liberadas", name: "rotinas", defaultValue: [],
            type: SelectCheckBoxField, props:{
                dataURI:"thunder/rotina?presets",
                dataParser: response=>{
                    return response.rotina && response.rotina.map((item)=>{
                        const preset = JSON.parse(item.preset||null);
                        return ({label: item.descricao+(preset?" - "+preset.label:""), value: item.id+(preset?"_"+preset.label:""), rotina: item.id, preset: preset && preset.label});
                    });
                },
                isSearchable: true,
            },
        },
    ]
    const formButtons:DaileonFormButton[] = [
        {label: "Reverter",
            props: {variant: "contained", color: "default"},
            action: async (form)=>{
                await form.clear();
            },
        },
        {label: "Salvar", isSubmit: true,
            props: {variant: "contained", color: "primary"},
            action: (form)=>{
                const allValid = form.validateAll();
                if(allValid){
                    const values = form.getValues();
                    save(values);
                } else {
                    form.highlightInvalid();
                }
            },
        },
    ]

    return <Paper className={classes.paper}>
            <Grid container direction='row' justify='space-between' alignItems='center'>
                <Typography variant='h5'>{editCode ? `Editando usuário (cod. ${editCode})` : "Novo usuário"}</Typography>
                <Button variant='contained' color='default' onClick={()=>props.history.goBack()}>Voltar</Button>
            </Grid>
            <Divider className={classes.divider}/>

            <DaileonForm
                fields={formFields}
                buttons={formButtons}
                initialValues={initialValues}
                />
        </Paper>;
}

export default CadastroUsuario;