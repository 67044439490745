
import Util, { DEV_MODE } from './../../../main/service/util';

interface SessionType {
    activated:boolean;
    data: any;
    token: string|null,
}

// Fallback caso nao exista um cache
const sessionDefault:SessionType = {
    activated: false,
    data: {},
    token: null,
}
// Sessao salva no cache quando a pagina foi aberta
const cachedBase = localStorage.getItem('cached');

export default class AuthService {
    private static currentSession:SessionType = {...sessionDefault};

    // Getters
    static authenticated():boolean{
        return this.currentSession.activated;
    }
    static getData(){
        return this.currentSession.data || {};
    }
    static getToken(){
        return this.currentSession.token;
    }

    // Funcoes de nivel
    static checkPermission(path:string){
        if(!this.currentSession.activated) return false;
        if(!this.currentSession.data.acesso) return false;
        return this.currentSession.data.acesso.find((a:any)=>a.path===path)!=null;
    }

    // Caching
    static loadCache(){
        this.currentSession = cachedBase ? JSON.parse(Util.decode(cachedBase)) : {...sessionDefault};
        console.log("Loading from cache: ",this.currentSession.activated);
        return this.currentSession.activated;
    }
    static saveCache(){
        const cached_data = Util.encode(JSON.stringify(this.currentSession));
        localStorage.setItem('cached',cached_data);
    }
    static clearCache(){
        localStorage.removeItem('cached');
    }

    // Funcoes de login
    static async login(fields:any){
        const postData = {
            username: fields['user'],
            password: fields['pass'],
            action: 'login',
        };

        const response = await Util.backendRequest('login','POST',JSON.stringify(postData));
        const responseData = response.data || {};
        console.log('Login Response: ',response);
        if(response.status!==200 && !responseData.error){
            if(DEV_MODE) {
                let fullErr = JSON.stringify(responseData);
                return [false, [{desc: `Status: ${response.status} ${response.statusText}\n\n${fullErr}`}]];
            }
            else if(response.status===500){
                return [false, [{desc: "O sistema está em manutenção, desculpe o transtorno."}]];
            } else {
                return [false, [{code: response.status, desc: response.statusText}]];
            }
        }
        if(responseData.success){
            this.currentSession.token = responseData.token;
        }
        return [responseData.success, responseData.error];
    }

    static logout(){
        const postData = {
            action: 'logout',
        };
        Util.backendRequest('login','POST',JSON.stringify(postData)).then((response)=>{
            console.log("Logout response:",response);
        });

        this.clearCache();
        this.currentSession = {...sessionDefault};
    }

    static async fetchSession(){
        const response = await Util.backendRequest('login','GET');
        const responseData = response.data;
        if(response.status!==200 && !responseData.error){
            if(DEV_MODE) return [false, [{desc: `Status: ${response.status} ${response.statusText}\n\n${responseData}`}]];
            else if(response.status===500){
                return [false, [{desc: "O sistema está em manutenção, desculpe o transtorno."}]];
            } else {
                return [false, [{code: response.status, desc: response.statusText}]];
            }
        }
        if(responseData){
            console.log("Fetched session data: ", responseData);

            // Se a sessao for encerrada, limpa todos os dados da sessao anterior
            if(!responseData.activated) this.currentSession = {...sessionDefault};

            this.currentSession.activated = responseData.activated;
            if(!this.currentSession.data) this.currentSession.data = {};
            // Copia todos os dados do objeto "data" pra sessao
            for (const key in responseData.data) {
                if (responseData.data.hasOwnProperty(key)) {
                    const element = responseData.data[key];
                    this.currentSession.data[key] = element;
                }
            }
            this.saveCache();
        }
        return [this.currentSession.activated, responseData.error];
    }
}