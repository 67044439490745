import React from 'react';
import { withRouter, Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { ThemeProvider, CSSProperties } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import * as Colors from '@material-ui/core/colors';
import './App.css';

// Servicos
import Util from './main/service/util';
import AuthService from './modules/auth/service/authService';
import NotificationService from './main/service/notificationService';

// Compoenents
import AppNav from './main/container/appNav';
import ModalSimpla from './main/component/modalSimpla';
import ProtectedRoute from './modules/auth/component/protectedRoute';
import ModalService from './main/service/modalService';

import * as ErrorPages from './main/container/errorPages';

// Telas
import Login from './modules/auth/component/login';
import Principal from './modules/principal/principal';
import Acesso from './modules/acesso/acesso';
import MarkdownEditor from './modules/markdown/container/markdownEditor';
import CadastroAcesso from './modules/acesso/cadastro';
import Nivel from './modules/nivel/nivel';
import CadastroNivel from './modules/nivel/cadastro';
import Consulta from './modules/extrator/component/consulta';
import NovaConsulta from './modules/extrator/component/novaConsulta';
import Usuario from './modules/usuario/usuario';
import CadastroUsuario from './modules/usuario/cadastro';
import Sessoes from './modules/auth/sessoes';

const defaultLocation = process.env.PUBLIC_URL;

const Telas = [
    { path: "/principal", component: Principal },
    { path: "/plano", component: MarkdownEditor },
    { path: "/acesso", component: Acesso },
    { path: "/acesso-cadastro", component: CadastroAcesso },
    { path: "/usuario", component: Usuario },
    { path: "/usuario-cadastro", component: CadastroUsuario },
    { path: "/nivel", component: Nivel },
    { path: "/nivel-cadastro", component: CadastroNivel },
    { path: "/consulta", component: Consulta },
    { path: "/consulta-novo", component: NovaConsulta },
    { path: "/sessoes", component: Sessoes },
];

// Tema
const theme = createMuiTheme({
    palette: {
        primary: {
            main: Colors.blue[400],
        },
        secondary: {
            main: Colors.red[800],
        },
    }
});

const semiHiddenDiv: CSSProperties = {
    color: 'transparent',
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
}

const App: React.FC<RouteComponentProps> = (props) => {
    const [, updateState] = React.useState();
    const reloadState = React.useCallback(() => updateState({}), []);
    const [screenSize, setScreenSize] = React.useState({ width: 0, height: 0 });
    const [installEvent, setInstallEvent] = React.useState<any>(null);

    React.useEffect(() => { // A.K.A ComponentDidMount
        console.log("Sistema Iniciado");
        if (AuthService.loadCache()) {
            console.log("Cache carregado, atualizando sessao...");

            props.history.replace(Util.getDesiredPath());

            AuthService.fetchSession().then(([success,errors])=>{
                if(!success){
                    ModalService.popoutError(errors);
                    return;
                }
                reloadState();
                // Inicia o servico de notificacoes (atualiza automaticamente)
                NotificationService.beginInterval();
            });
        }
        console.log("Endereco base: ", defaultLocation);
        // Quando o state do modal eh alterado atualiza o state
        ModalService.registerCallback(() => reloadState());

        // Escuta o evento de resize pra arrumar o bug
        window.addEventListener("resize", () => {
            //console.log("Resized: "+window.innerWidth+" x "+window.innerHeight);
            setScreenSize({ width: window.innerWidth, height: window.innerHeight });
        });

        window.addEventListener('beforeinstallprompt', (e) => {
            // Salva referencia ao evento de instalacao
            console.log("Web App install available");
            setInstallEvent(e);
        });       

    }, [reloadState, props.history]);

    const screenProps = {

    };

    const loginSubmit = async (fields) => {
        const loadingModal = ModalService.common.loading;

        ModalService.add(loadingModal);
        // Faz o login
        let [success, errors] = await AuthService.login(fields);
        if(!success){
            ModalService.remove(loadingModal);
            ModalService.popoutError(errors);
            return;
        }
        // Pega os dados
        console.log("Obtendo dados da sessão...");
        [success, errors] = await AuthService.fetchSession();
        if(!success){
            ModalService.remove(loadingModal);
            ModalService.popoutError(errors);
            return;
        }
        ModalService.remove(loadingModal);
        props.history.replace(Util.getDesiredPath());

        // Inicia o servico de notificacoes (atualiza automaticamente)
        NotificationService.beginInterval();

        console.log("Index:",Util.getHomePath());
    }
    const logout = () => {
        NotificationService.stopInterval();
        AuthService.logout();
        reloadState();
    }

    return <ThemeProvider theme={theme}>
        <AppNav hideNav={!AuthService.authenticated()} logout={logout} installEvent={installEvent} setInstallEvent={setInstallEvent} >
            <Switch>
                <Route path={"/login"} render={(props) => <Login submit={loginSubmit} {...props} {...screenProps} />} />

                {Telas.map((tela: any, i: number) => <ProtectedRoute key={i} path={tela.path} component={tela.component} exact {...screenProps} />)}

                <Route path={"/404"} component={ErrorPages.Error404} />
                <Route path={"/401"} component={ErrorPages.Error401} />
                <Route path={"/500"} component={ErrorPages.Error500} />

                <Route path={"/"} exact component={() => <Redirect to={AuthService.authenticated()?Util.getHomePath():"/login"} />} />

                <Redirect to={"/404"} />
            </Switch>
            <footer><br/>
                <small style={{display:'block',textAlign:'center'}}>
                    <a className="undecorated" href="https://www.nodesistemas.com.br/" target='_blank' rel="noopener noreferrer">
                        Node Sistemas LTDA
                    </a> © 2019 | {AuthService.getToken()}
                    </small>
            </footer>
            <div style={semiHiddenDiv}>{`${screenSize.width} x ${screenSize.height}`}</div>
        </AppNav>
        <ModalSimpla />
    </ThemeProvider>;
}

export default withRouter(App);