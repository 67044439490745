import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import DaileonTable, { TableColumn, setColumnSize } from '../../main/component/daileonTable';

import { Paper, makeStyles, createStyles, Typography, Divider, Grid, IconButton, Menu, MenuItem, Chip } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import Util from '../../main/service/util';
import ModalService from '../../main/service/modalService';
import ProtectedButton from '../auth/component/protectedButton';
import AuthService from './../auth/service/authService';
import { format } from 'date-fns';

const useStyles = makeStyles( theme => createStyles({
        paper: {
            padding: theme.spacing(1),
        },
        divider: {
            margin: theme.spacing(1,0,1,0),
        },
        addButton: {
            color: theme.palette.common.white,
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[600],
            },
        },
        cellIcon: {
            fontSize: '1.25rem',
            marginRight: 5,
        },
        chips: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }),
);

const Nivel: React.FC<RouteComponentProps> = (props)=>{
    const PATH_CADASTRO = "/nivel-cadastro";

    const classes = useStyles({});

    const [dataList,setDataList] = useState<any[]>([]);
    const [loading,setLoading] = useState<boolean>(false);
    const [actionAnchors,setActionAnchors] = useState<any>({});

    React.useEffect(()=>{
        fetchData();
    },[]);

    const fetchData = async ()=>{
        setLoading(true);
        const [data] = await Util.backendRequestHandled('auth/nivel','GET');
        if(data) setDataList(data.nivel);
        setLoading(false);
    }

    //------- DATATABLE ------------

    const tableColumns:TableColumn[] = [
        { title: 'Codigo', field: 'id', ...setColumnSize(30,null,{textAlign:'center'}) },
        { title: 'Nome', field: 'nome' },
        { title: 'Criação', field: 'timestamp', render: rowData=>{
            const date = new Date(rowData.timestamp);
            return <>
                <div style={{display:'flex'}}>
                    <Icons.Today className={classes.cellIcon}/>
                    <span>{format(date,'dd/MM/yy')}</span>
                </div>
                <div style={{display:'flex'}}>
                    <Icons.AccessTime className={classes.cellIcon}/>
                    <span>{format(date,'HH:mm:ss')}</span>
                </div>
            </>;
        }},
        { title: 'Acesso', render: rowData=>{
                const acessos = JSON.parse(rowData.acessos||null);
                return acessos && <div className={classes.chips}>{acessos.map((v,i)=><Chip key={i} color='default' size='small' label={v.label}/>)}</div>;
            }
        },
        { title: 'Ações', hidden: !AuthService.checkPermission(PATH_CADASTRO), ...setColumnSize(30,{textAlign:'right'},{textAlign:'right'}), render: rowData => {
            const anchor = actionAnchors[rowData.id] || null;
            const handleClick = evt=>setActionAnchors({...actionAnchors, [rowData.id]:evt.currentTarget});
            const clickEdit = ()=>{
                const acessos = JSON.parse(rowData.acessos||null);
                props.history.push(PATH_CADASTRO, {...rowData, acesso: acessos && acessos.map(v=>({label: v.label, value: String(v.id)}))});
                handleClose();
            };
            const clickRemove = ()=>{
                const confirmModal = ModalService.common.confirm(
                    'Remover nível "'+rowData.nome+'" ?',
                    'Tem certeza que deseja excluir o nível "'+rowData.nome+'"?',
                    async ok=>{
                        if(ok){
                            await Util.backendRequestHandled('auth/nivel?id='+rowData.id,'DELETE',null,true,true,true);
                            fetchData();
                        }
                    },'EXCLUIR','Cancelar','secondary'
                );
                ModalService.add(confirmModal);
                handleClose();
            };
            const handleClose = ()=>setActionAnchors({...actionAnchors, [rowData.id]:null});
            return <>
                <IconButton aria-label="actions" onClick={handleClick}>
                    <Icons.MoreVert/>
                </IconButton>
                <Menu
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={clickEdit}>Editar</MenuItem>
                    <MenuItem onClick={clickRemove}>Remover</MenuItem>
                </Menu>
            </>;
        }},
    ];

    return <Paper className={classes.paper}>
            <Grid container direction='row' justify='space-between' alignItems='center'>
                <Typography variant='h5'>Níveis de Acesso</Typography>
                <ProtectedButton path={PATH_CADASTRO} variant='contained' color='inherit' className={classes.addButton} onClick={()=>props.history.push(PATH_CADASTRO)}>Novo Nível</ProtectedButton>
            </Grid>
            <Divider className={classes.divider}/>
            <DaileonTable
                title="Definições de Níveis"
                columns={tableColumns}
                options={{}}
                isLoading={loading}
                data={dataList||[]}
                />
        </Paper>;
}

export default Nivel;