import React from 'react';
import { Link as RouteLink } from 'react-router-dom';

import { Link } from '@material-ui/core';

import ReactMarkdown from 'react-markdown';

const MarkDown: React.FC<ReactMarkdown.ReactMarkdownProps> = (props)=>{
    const routerLinkComponent = ({href,children}:any)=>{
        return <Link component={RouteLink} to={href}>{children}</Link>;
    }
    return <div>
        <ReactMarkdown escapeHtml={false} renderers={{link: routerLinkComponent}} {...props}/>
    </div>;
}

export default MarkDown;