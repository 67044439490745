import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import DaileonTable, { TableColumn, setColumnSize } from '../../main/component/daileonTable';

import { Paper, makeStyles, createStyles, Typography, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemAvatar, Avatar, ListItemText, Icon, Chip } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import Util from '../../main/service/util';
import ProtectedButton from './component/protectedButton';
import DaileonForm, { FormFields, DaileonFormButton, Space } from '../../main/component/daileonForm';
import { DateTimePicker } from '../../main/component/dateTimePicker';
import SelectTagsField from '../../main/component/selectTagsField';
import { CompatibleTextField, CompatibleSwitch } from '../../main/component/daileonCommonInput';

import { format, formatDistanceStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import AuthService from './service/authService';
import ModalService from '../../main/service/modalService';
import BBCode from '../../main/component/bbcode';
import AutocompleteField from '../../main/component/autocompleteField';

const useStyles = makeStyles( theme => createStyles({
        paper: {
            padding: theme.spacing(1),
        },
        divider: {
            margin: theme.spacing(1,0,1,0),
        },
        addButton: {
            color: theme.palette.common.white,
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[600],
            },
        },
        cellIcon: {
            fontSize: '1.25rem',
            marginRight: 5,
        },
        modalPaper:{
            minWidth: '60vw',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        typeAvatar: {
            
        },
    }),
);

const Sessoes: React.FC<RouteComponentProps> = (props)=>{
    const PATH_ATIVIDADES = "/atividades";
    const initialValues = props.location.state;
    
    const classes = useStyles({});

    const initDateStart = null; //startOfWeek(new Date())
    const initDateEnd = null; //new Date()

    const [minDate,setMinDate] = useState<Date>(initDateStart);
    const [maxDate,setMaxDate] = useState<Date>(initDateEnd);

    const [dataList,setDataList] = useState<any[]>([]);
    const [usuarioList,setUsuarioList] = useState<any[]>([]);
    const [nivelList,setNivelList] = useState<any[]>([]);

    const [loading,setLoading] = useState<boolean>(false);
    const [actionAnchors,setActionAnchors] = useState<any>({});

    React.useEffect(()=>{
        fetchData({
            //inicio: Math.round(initDateStart.getTime()/1000),
            //fim: Math.round(initDateEnd.getTime()/1000),
        });
    },[]);

    const fetchData = async (filtros?:any)=>{
        setLoading(true);
        const [data] = await Util.backendRequestHandled('auth/sessao?'+Util.urlParams(filtros),'GET');
        console.log(data);
        if(data) {
            setDataList(data.sessao);
            if(data.usuario) setUsuarioList(data.usuario.map(i=>({label: i.nome+" ("+i.nomeDisplay+")", value: i.id})));
            if(data.nivel) setNivelList(data.nivel.map(i=>({label: i.nome, value: i.id})));
        }
        setLoading(false);
    }

    //------- FORM ------------
    const dateFormat = 'HH:mm dd/MM/yy';
    const formFields:FormFields = [
        {label: "Usuários", name: "usuario", defaultValue: [], formatValue: v=>(v&&v.map(i=>i.value))||"",
            type: SelectTagsField, props:{
                options: usuarioList,
            },
        },
        {label: "Nível", name: "nivel", defaultValue: [], formatValue: v=>(v&&v.map(i=>i.value))||"",
            type: SelectTagsField, props:{
                options: nivelList,
            },
        },
        {label: "Iniciado de", name: "inicio", defaultValue: initDateStart, grid:{ sm:5, md:3 },
            type: DateTimePicker, props: {maxDate: maxDate, format: dateFormat}, formatValue: v=>(v&& Math.round(v.getTime()/1000))||"",
        },
        {append: <Space/>, grid:{sm: 'auto', style:{padding:0}}},
        {label: "Até", name: "fim", defaultValue: initDateEnd, grid:{ sm:5, md:3 },
            type: DateTimePicker, props: {minDate: minDate, format: dateFormat}, formatValue: v=>(v&&Math.round(v.getTime()/1000))||"",
        },
        {label: "Apenas sessões ativas", name: "ativo", defaultValue: false,
            type: CompatibleSwitch, props: { }
        },
    ]
    const formButtons:DaileonFormButton[] = [
        {label: "Limpar",
            props: {variant: "contained", color: "default"},
            action: async (form)=>{
                await form.clear();
            },
        },
        {label: "Consultar", isSubmit: true,
            props: {variant: "contained", color: "primary"},
            action: (form)=>{
                const allValid = form.validateAll();
                if(allValid){
                    const values = form.getValues();
                    fetchData(values);
                } else {
                    form.highlightInvalid();
                }
            },
        },
    ];
    const onFormUpdate = (form:DaileonForm)=>{
        const codigo = form.getFieldValue('codigo');
        // Se setar o código, nao filtra por data
        if(codigo) form.setValues({inicio: null, fim: null});

        const inicio = form.getFieldValue('inicio',true);
        const fim = form.getFieldValue('fim',true);
        setMinDate(inicio);
        setMaxDate(fim);
    }

    //------- DATATABLE ------------
    const tableColumns:TableColumn[] = [
        { title: 'Sessões', ...setColumnSize(20,null,{textAlign:'-webkit-center'}), render: rowData=>{
            return <Chip size='small' label={rowData.sessao_ativo?"Online":"Offline"} style={{backgroundColor: rowData.sessao_ativo?'green':'grey'}}/>;
            //return <Avatar style={{backgroundColor: rowData.sessao_ativo?'green':'grey', width: '15px', height: '15px'}}/>;
        }},
        { title: 'Sessões Ativas', field: 'sessao_count', ...setColumnSize(30,null,{textAlign:'center'}) },
        { title: 'Usuário', field: 'usuario_nome', render: rowData=>{
            return rowData.usuario_nome + " (" +rowData.usuario_nomeDisplay+ ")";
        }},
        { title: 'Nível', field: 'nivel_nome' },
        { title: 'Início', field: 'timestamp', render: rowData=>{
            if(!rowData.sessao_timestamp) return "";
            const date = new Date(Number(rowData.sessao_timestamp)*1000);
            return <>
                <div style={{display:'flex'}}>
                    <Icons.Today className={classes.cellIcon}/>
                    <span>{format(date,'dd/MM/yy')}</span>
                </div>
                <div style={{display:'flex'}}>
                    <Icons.AccessTime className={classes.cellIcon}/>
                    <span>{format(date,'HH:mm:ss')}</span>
                </div>
            </>;
        }},
        { title: 'IP', field: 'sessao_ip' },
        { title: 'Ações', ...setColumnSize(100,{textAlign:'right'},{textAlign:'right'}), render: rowData => {
            const anchor = actionAnchors[rowData.id] || null;
            const handleClick = evt=>setActionAnchors({...actionAnchors, [rowData.id]:evt.currentTarget});
            const handleClose = ()=>setActionAnchors({...actionAnchors, [rowData.id]:null});
            return <>
                <Tooltip title="Outras Opções">
                    <IconButton aria-label="actions" onClick={handleClick}>
                        <Icons.MoreVert/>
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>Oi oi</MenuItem>
                </Menu>
            </>;
        }},
    ];

    return <Paper className={classes.paper}>
            <Grid container direction='row' justify='space-between' alignItems='center'>
                <Typography variant='h5'>Sessões</Typography>
            </Grid>
            <Divider className={classes.divider}/>
            <DaileonForm
                fields={formFields}
                buttons={formButtons}
                initialValues={initialValues}
                onChange={onFormUpdate}
                />
            <Divider className={classes.divider}/>
            <DaileonTable
                title="Sessões"
                columns={tableColumns}
                options={{}}
                isLoading={loading}
                data={dataList||[]}
                />
        </Paper>;
}

export default Sessoes;